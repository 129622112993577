<template lang="pug">
    .popup
        .popup__inner
            h3 Спасибо!
            hr
            p(v-html="'Для подтверждения участия в&nbsp;розыгрыше подтвердите свою&nbsp;почту!'")
            h6 {{ email }}
            small Мы выслали письмо вам на почту
            cup(activeColor="gold")
</template>

<script>
import Cup from "@/components/game/decor/cup";

export default {
    name: "SuccessPopup",
    components: {Cup},
    props: {
        email: {
            required: true
        }
    },
    methods: {
        handleDocumentClick(e) {
            if (!e.target.closest('.popup__inner')) {
                this.$emit('close');
            }
        }
    },
    mounted() {
        const app = document.querySelector('#app');
        if (window.isMobileOrTablet) {
            app.style.cssText = 'overflow: hidden;';
        } else {
            window.app_fullpage.setAllowScrolling(false);
            window.app_fullpage.setKeyboardScrolling(false);
        }
        app.addEventListener('click', this.handleDocumentClick);
    },
    beforeDestroy() {
        const app = document.querySelector('#app');
        if (window.isMobileOrTablet) {
            app.style.cssText = '';
        } else {
            window.app_fullpage.setAllowScrolling(true);
            window.app_fullpage.setKeyboardScrolling(true);
        }
        app.removeEventListener('click', this.handleDocumentClick)
    }
}
</script>

<style scoped lang="scss">
.popup {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.66);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: rem(672px);

        padding: 3.75rem 5rem 1.2rem;

        border: 1px solid #a77f52;
        border-radius: 0.5rem;
        background: #fff;

        color: #000;
        text-align: center;
        cursor: initial;
    }

    h3 {
        margin-bottom: 1.8rem;
        font-size: rem(34px);
        letter-spacing: 0.42rem;
        text-transform: uppercase;
        color: #a77f52;
    }
    h6 {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.25rem;
    }
    hr {
        width: 6.5rem;
        height: 1px;
    }

    p {
        margin: 1.5rem 0 1.6rem;
        font-size: 1.5rem;
        line-height: 1.23;
        font-weight: 300;
    }

    small {
        margin: 1.7rem 0 1.6rem;
        font-weight: 300;
    }

    .cup {
        position: relative;
        width: 6rem;
        height: 6rem;

        &::v-deep span {
            transform: rotate(141deg);
        }
    }

    @include mobile_or_P {
        position: fixed;
        &__inner {
            width: 78vw;
            padding: 4.9rem 2rem 4.4rem;
            border-radius: 1rem;
        }
        h3 {
            margin-bottom: 1rem;
            font-size: 2.85rem;
        }
        h6 {
            font-size: 1.75rem;
        }
        hr {
            width: 13.5rem;
        }
        p {
            margin: 1.2rem 0 0.8rem;
            font-size: 1.9rem;
        }
        small{
            margin: 1.2rem 0 2.6rem;
            font-size: 1.9rem;
            line-height: 1.2;
        }
        .cup{
            width: 7.5rem;
            height: 7.5rem;
        }
        @include landscape{
            &__inner{
                padding: 3rem 2rem 2rem;
            }
        }
    }
}
</style>
