export default {
    bind(el,{value}){
        el.addEventListener('click',()=>{
            let to = document.querySelector(value);
            if (to){
                let offset = to.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top:offset,
                    behavior: 'smooth'
                })
            }
        })
    },
}
