<template lang="pug">
    .timer(:class="activeColor" ref="timer")
        svg(width='70' height='71' viewbox='0 0 70 71' fill='none' xmlns='http://www.w3.org/2000/svg')
            rect(ref="rect" x='2' y='2' width='60' height='60' rx='30' fill='transparent' stroke='#EC3535' stroke-width='4')
        .time 00:
            b(ref="time")
</template>

<script>
export default {
    name: "timer",
    props: {
        activeColor: {
            required: true,
        },
        duration: {
            required: true,
        },
    },
    methods:{
        update(){
            let newTime = this.$refs.time.innerHTML - 1;
            this.$refs.time.innerHTML = this.addZero(newTime);
            if (newTime == 0){
                clearInterval(window.timerId);
            }
        },
        addZero(n) {
            return String(n).length>1 ? n : '0'+n;
        }
    },
    mounted() {
        this.$refs.rect.style.cssText = `stroke-dasharray: ${this.$refs.rect.getTotalLength()}; stroke-dashoffset: ${this.$refs.rect.getTotalLength()};`;
        this.$refs.time.innerHTML = this.addZero(this.duration);
        window.timerId = setInterval(this.update, 1000);
        setTimeout(()=>{
            this.$refs.timer.classList.add('start');
        })
    },
    beforeDestroy() {
        clearInterval(window.timerId);
    }
}
</script>

<style scoped lang="scss">
.timer{
    position: relative;

    display: flex;
    align-items: center;

    width: rem(234px);
    height: rem(234px);
    padding-top: 0.5rem;
    padding-left: 2.5rem;
    border: 2px solid $white;
    border-radius: 50%;
    &.black svg rect{
        stroke: $black;
    }
    &.blue svg rect{
        stroke: $blue;
    }
    &.gold svg rect{
        stroke: $gold;
    }
    &.pink svg rect{
        stroke: $pink;
    }
    &.vanillia svg rect{
        stroke: $vanillia;
    }
    &.start {
        rect {
            stroke-dashoffset: 0 !important;
            transition: 20s linear;
        }
    }
}
.time {
    font-size: 1.5rem;
    font-weight: 100;
    b{
        font-size: rem(92px);
        letter-spacing: -0.2rem;
    }
}
svg {
    position: absolute;
    top: -3px;
    left: -3px;
    width: rem(240px);
    height: rem(240px);

    rect {
        width: rem(232px);
        height: rem(232px);
        rx: rem(117px);
        stroke-linecap: round;
    }
}
@include mobile_or_P {
    .timer {
        width: 6.75rem;
        height: 6.75rem;
        padding-left: 1rem;
        padding-top: 0;
        border-width: 1px;
        .time{
            font-size: 1rem;
            b{
                font-size: 2.6rem;
            }
        }
    }
    svg{
        width: calc(6.75rem + 4px);
        height:calc(6.75rem + 4px);
        rect{
            width: 6.75rem;
            height: 6.75rem;
        }
    }
}
</style>
