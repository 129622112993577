import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import '@/assets/scss/main.scss';
// import '@/assets/js/polyfill.js';

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

var SocialSharing = require('vue-social-sharing');
Vue.use(SocialSharing);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import MinHeight from '@/directives/min-height.directive.js'
Vue.directive('minHeight', MinHeight)

import Anchor from '@/directives/anchor.directive.js'
Vue.directive('anchor', Anchor)

import axios from 'axios'

axios.defaults.baseURL = window.location.hostname === 'localhost' ? 'https://app.jardinart.ru/api/' : 'https://jardin.dev-demo.online/api/';

import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
