export default {
    data: () => ({
        parallaxContainer: null,
    }),
    methods: {
        initParallax(container) {
            this.parallaxContainer = container;
            const changeLimit = 200;
            let moveCounter = 0;
            const parallaxItems = Array.prototype.slice.call(document.querySelectorAll('[data-parallax]'), 0);
            this.parallaxContainer.onmousemove = ({pageX, pageY}) => {

                moveCounter++;
                if (moveCounter >= changeLimit) {
                    moveCounter = 0;
                    this.activeI++;
                    if (this.activeI >= 5) {
                        this.activeI = 0;
                    }
                }
                parallaxItems.forEach(item => {
                    let k = item.getAttribute('data-parallax');
                    let [k_x, k_y, k_r] = k.split(':');
                    let offset = {
                        x: pageX / window.innerWidth - 0.5,
                        y: pageY / window.innerHeight - 0.5,
                    };
                    item.style.cssText = `transform: translate3d(${offset.x * k_x}rem,${offset.y * k_y}rem,0) rotate(${offset.y * k_r}deg)`
                })
            }
        },
    },

    beforeDestroy() {
        if (this.parallaxContainer) {
            this.parallaxContainer.onmousemove = null;
        }
    }
}
