<template lang="pug">
    .taste-number(:class="activeColor") Вкус
        i {{ number }}
        | из 5
</template>

<script>
export default {
    name: "tasteNumber",
    props: {
        number: {
            required: true
        },
        activeColor: {
            required: true,
        }
    }
}
</script>

<style scoped lang="scss">
.taste-number {
    font-size: rem(30px);
    &.black{
        i:before {
            border: 1px solid #fff;
            background-color: $black;
        }
    }
    &.blue{
        i:before {
            background-color: $blue;
        }
    }
    &.pink{
        i:before {
            background-color: $pink;
        }
    }
    &.vanillia{
        i:before {
            background-color: $vanillia;
        }
    }
    &.gold{
        i:before {
            background-color: $gold;
        }
    }

    i {
        position: relative;
        display: inline-block;
        width: rem(30px);
        margin: 0 1rem;
        text-align: center;
        transform: translate3d(0, 0, 0);

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            transition: .3s;
        }
    }
    @include mobile_or_P{
        font-size: 1.65rem;
        text-align: center;
        i{
            margin: 0 1.3rem;
            &:before{
                width: 3.5rem;
                height: 3.5rem;
            }
        }
    }
}
</style>
