<template>
    <div class="home">
        <div class="section" data-anchor-target="intro">
            <intro @goToConditions="goToAnchor('game')"/>
        </div>
        <!--<div class="section" data-anchor-target="conditions">
            <conditions @goToGame="goToAnchor('game')"/>
        </div>-->
        <div class="section" data-anchor-target="game">
            <game @goToGame="goToAnchor('game')"/>
        </div>
       <!-- <div class="section" data-anchor-target="prizes">
            <prizes/>
        </div>-->

        <div class="anchors" ref="anchorsContainer">
            <div class="anchor" :class="{active: activeAnchor==='intro'}" data-anchor-id="intro"
                 data-analitics-id="1"></div>
            <!--<div class="anchor" :class="{active: activeAnchor==='conditions'}" data-anchor-id="conditions"
                 data-analitics-id="2"></div>-->
            <div class="anchor" :class="{active: activeAnchor==='game'}" data-anchor-id="game"
                 data-analitics-id="3"></div>
            <!--<div class="anchor" :class="{active: activeAnchor==='prizes'}" data-anchor-id="prizes"
                 data-analitics-id="4"></div>-->
        </div>
        <audio src="/silence.mp3" id="versionAudio"></audio>
    </div>
</template>

<script>
import game from '@/components/game'
import intro from '@/components/intro'
/*import conditions from '@/components/conditions'
import prizes from '@/components/prizes'*/
import fullpage from 'fullpage.js'

export default {
    name: "Home",
    components: {
        game,
        intro,
        //conditions,
        //prizes,
    },
    data: () => ({
        activeAnchor: 'intro',
        analiticsNextStep: 0,
    }),
    methods: {
        initFullPage() {
            const anchors = Array.from(document.querySelectorAll('[data-anchor-target]')).map(el => el.getAttribute('data-anchor-target'));

            window.app_fullpage = new fullpage('.home', {
                anchors: anchors,
                onLeave: (origin, destination) => {
                    if (!destination.item.classList.contains('animated')) {
                        destination.item.classList.add('animated');
                    }
                    this.activeAnchor = destination.anchor;
                    if (window.location.hostname !== 'localhost') {


                        let dest = destination.index * 25 + 25;
                        if (dest === 25) {
                            dest = 0
                        }
                        if (dest > this.analiticsNextStep) {
                            this.analiticsNextStep = dest;
                            ga('send', 'event', 'scroll', dest + '%');
                            console.log(`ga('send', 'event', 'scroll', ${dest}%)`);
                        }
                    }
                }
            });
        },

        initAnchors() {
            const app = document.querySelector('#app')
            const anchorsContainer = this.$refs.anchorsContainer;
            const anchorsList = Array.from(anchorsContainer.querySelectorAll('.anchor'));
            const anchorsSections = Array.from(document.querySelectorAll('[data-anchor-target]')).reverse();
            anchorsList.forEach(a => {
                const anchor = a.getAttribute('data-anchor-id');
                const analiticsId = a.getAttribute('data-analitics-id');
                a.addEventListener('click', () => {
                    this.goToAnchor(anchor);
                    if (window.location.hostname !== 'localhost') {
                        ga('send', 'event', 'send', 'click', 'point', analiticsId);
                        console.log(`ga('send', 'event','send', 'click', 'point',${analiticsId})`)
                    }
                });
            });
            if (window.isMobileOrTablet) {
                app.addEventListener('scroll', () => {
                    for (let a of anchorsSections) {
                        if (a.getBoundingClientRect().top < window.innerHeight / 2) {
                            this.activeAnchor = a.getAttribute('data-anchor-target');

                            if (!a.classList.contains('animated')) {
                                a.classList.add('animated');
                            }

                            return;
                        }
                    }
                });
            }
            app.appendChild(anchorsContainer);
        },
        goToAnchor(anchor, fast) {
            const app = document.querySelector('#app')
            if (window.isMobileOrTablet) {
                const to = document.querySelector(`[data-anchor-target=${anchor}]`);
                if (to) {
                    app.scrollTo({
                        top: to.getBoundingClientRect().top + app.scrollTop,
                        behavior: fast ? 'instant' : "smooth"
                    });
                }
            } else {
                if (fast) {
                    window.app_fullpage.silentMoveTo(anchor);
                } else {
                    window.app_fullpage.moveTo(anchor);
                }
            }
        },
        initTimeAnalitics() {
            if (window.location.hostname === 'localhost') return;
            let time = 0;
            setInterval(() => {
                time += 30;
                console.log(`ga('send', 'event', 'time', ${time})`);
                ga('send', 'event', 'time', time);
            }, 30000)
        },
        initScrollAnalitics() {
            const app = document.querySelector('#app')
            let pageH = document.querySelector('#app .home').offsetHeight - document.documentElement.clientHeight;
            app.addEventListener('scroll', () => {
                if (app.scrollTop / pageH * 100 - app.scrollTop / pageH * 100 % 25 > this.analiticsNextStep) {
                    this.analiticsNextStep += 25;
                    if (this.analiticsNextStep > 100) return;
                    ga('send', 'event', 'scroll', this.analiticsNextStep + '%');
                    console.log(`ga('send', 'event', 'scroll', ${this.analiticsNextStep}%)`);
                }
            });
        }
    },
    mounted() {
        if (!window.isMobileOrTablet) {
            this.initFullPage();
        }
        this.initAnchors();

        const versionAudioFix = () => {
            document.getElementById('versionAudio').play();
            window.removeEventListener('click', versionAudioFix);
        };
        window.addEventListener('click', versionAudioFix);
        //this.goToAnchor('intro', true);

        this.initTimeAnalitics();
        if (window.isMobileOrTablet && window.location.hostname !== 'localhost') {
            this.initScrollAnalitics();
        }
    }
};
</script>

<style scoped lang="scss">
@import "~fullpage.js/dist/fullpage.min.css";

.home {

}

.anchors {
    position: fixed;
    z-index: 10;
    top: 50vh;
    left: calc((100vw - 99rem) / 2 * 0.67);
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;

    .anchor {
        position: relative;
        width: 1rem;
        height: 1rem;
        margin-bottom: rem(37px);
        border-radius: 50%;
        background: #fff;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) scale(0.5);
            width: rem(36px);
            height: rem(36px);
            border: 1px solid #5d4c35;
            border-radius: 50%;
            pointer-events: none;
            opacity: 0;
            transition: .3s;
        }

        &:hover:before {
            border-radius: 50%;
            transform: translate3d(-50%, -50%, 0);
            opacity: 0.6;
        }

        &.active:before {
            border-radius: rem(6px);
            transform: translate3d(-50%, -50%, 0);
            opacity: 1;
        }
    }

    @include razr1600 {
        left: calc((100vw - 99rem) / 2 + 1rem);
    }
    @include razr1536 {
        left: 5rem;
    }
    @include razr1024 {
        left: 3rem;
    }
    @include mobile_or_P {
        left: 1.5rem;
        .anchor {
            width: 0.8rem;
            height: 0.8rem;
            margin-bottom: 2rem;
        }
    }
}
</style>
