<template lang="pug">
    component.button(:is="tag"
        v-bind="attributes")
        slot
        .hover
            slot
</template>

<script>
export default {
    name: "app-button",
    props: {
        tag: {
            default: 'div'
        }
    },
    computed: {
        attributes() {
            return this.$attrs;
        },
    }
}
</script>

<style scoped lang="scss">
.button {
    position: relative;
    color: $black;
    cursor: pointer;
    background: url(../../../assets/img/icons/arrow_right.png) no-repeat right 1.7rem center / 2.5rem auto,
    linear-gradient(45deg, #9d8a52 0%, #a38a61 50%, #cec2ab 80%, #d0bfa2 100%);

    &, .hover {
        display: inline-flex;
        align-items: center;
        min-width: rem(258px);
        height: rem(56px);
        padding-left: rem(26px);
        font-family: Circe, sans-serif;
        font-size: 1rem;
        letter-spacing: 0.21rem;
        text-transform: uppercase;
    }
    .hover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #af9a76;
        color: #af9a76;
        background: url(../../../assets/img/icons/arrow_right_gold.png) no-repeat right 1.7rem center / 2.5rem auto #000;
        clip-path: polygon(0 0,0 0,0 100%, 0 100%);
        transition: .3s;
    }

    &:hover {
        .hover {
            clip-path: polygon(0 0,100% 0,100% 100%, 0 100%);
        }
    }

    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }
}

@include mobile_or_P {
    .button {
        min-width: 18.75rem;
        height: 4rem;
        padding-left: 1.8rem;
        font-size: 1.17rem;
        letter-spacing: 0.25rem;
        background-size: 3.25rem auto, auto;
        .hover {
            display: none;
        }
    }
}
</style>
