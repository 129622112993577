<template lang="pug">
    .game(:class="activeColor" ref="game")
        .container
            transition(name="mini-cups")
                mini-cups(v-if="showMiniIcons"
                    :activeTaste="activeTaste"
                    :drawnTastes="drawnTastes")
        transition(name="screen" mode="out-in")
            intro(v-if="step==='intro'"
                key="intro"
                @start="startGame")
            tastes(v-else-if="step==='tastes'"
                key="tastes"
                :activeColor="activeColor"
                :taste="currentTaste"
                :currentWord="currentWord"
                @start="startRound")
            drawing(v-else-if="step==='drawing'"
                key="drawing"
                :activeColor="activeColor"
                :currentWord="currentWord"
                :roundId="round.id"
                @close="restartGame"
                @correctly="drawCorrectly"
                @wrong="drawWrong")
            draw-result(v-else-if="step==='draw-result-correct' || step==='draw-result-wrong'"
                key="draw-result"
                :activeColor="activeColor"
                :result="step==='draw-result-correct' ? 'correct' : 'wrong'"
                :taste="currentTaste"
                :currentWord="currentWord"
                @continue="nextTaste")
            finish(v-else-if="step==='finish'"
                key="finish"
                :game="game"
                :user="user"
                :token="token"
                :correctDrawings="correctDrawings"
                @goToCabinet="step='cabinet'")
            cabinet(v-else-if="step==='cabinet'"
                :user="user"
                :game="game"
                @restart="restartGame")
</template>

<script>
import Intro from "@/components/game/intro";
import MiniCups from "@/components/game/other/miniCups";
import Tastes from "@/components/game/tastes";
import Drawing from "@/components/game/drawing";
import DrawResult from "@/components/game/drawResult";
import Finish from "@/components/game/Finish";
import Cabinet from "@/components/game/Cabinet";

export default {
    name: "index",
    components: {
        Cabinet,
        Finish,
        DrawResult,
        Drawing,
        Tastes,
        MiniCups,
        Intro
    },
    data: () => ({
        step: "intro", // intro, tastes, drawing, draw-result-correct, draw-result-wrong, finish, cabinet
        activeTaste: 0,
        drawnTastes: 0,
        correctDrawings: 0,

        tastes: [
            {
                number: 1,
                name: 'Jardin',
                name2: 'Ristretto',
                description: 'Бленд арабики из Эфиопии и Колумбии с добавлением индийской робусты. Самая темная обжарка в коллекции капсульного кофе Jardin придает этому плотному, густому ристретто насыщенную горчинку.'
            },
            {
                number: 2,
                name: 'Jardin',
                name2: 'ALLONGE',
                description: 'Бережно обжаренный бленд восточноафриканской арабики для идеально сбалансированного лунго со сладковато-фруктовыми нотами в аромате и вкусе.'
            },
            {
                number: 3,
                name: 'Jardin',
                name2: 'ANDANTE',
                description: 'Бленд арабики из Восточной Африки и Колумбии с добавлением индийской робусты, которая придает интенсивному эспрессо с плотной текстурой мощный заряд кофейного вкуса.'
            },
            {
                number: 4,
                name: 'Jardin',
                name2: 'VANILLIA',
                description: 'Легкая обжарка бленда арабики из Гватемалы и Колумбии с добавлением ванили придает кофе бархатистый аромат и создает яркий многогранный вкус с долгим сладким послевкусием.'
            },
            {
                number: 5,
                name: 'Jardin',
                name2: 'VIVO',
                description: 'Благодаря деликатной обжарке арабики из Южной Америки и Восточной Африки достигается сбалансированный вкус кофе. Истинное удовольствие для тех, кто предпочитает мягкий кофе.'
            },
        ],

        game: {},
        user: {
            result: 0,
        },
        token: null,
        round: null,
    }),
    mounted() {
        let queryScreen = this.$route.query.screen;
        if (queryScreen === 'tastes') {
            this.step = 'tastes'
        }
        if (queryScreen === 'drawing') {
            this.step = 'drawing'
        }
        if (queryScreen === 'draw-result-correct') {
            this.step = 'draw-result-correct'
        }
        if (queryScreen === 'draw-result-wrong') {
            this.step = 'draw-result-wrong'
        }
        if (queryScreen === 'finish') {
            this.step = 'finish'
        }
        if (queryScreen === 'cabinet') {
            this.step = 'cabinet'
        }
    },

    /*created() {
        this.checkToken();
    },*/

    computed: {
        activeColor() {
            return this.activeTaste === 0 ? 'black' :
                this.activeTaste === 1 ? 'blue' :
                    this.activeTaste === 2 ? 'pink' :
                        this.activeTaste === 3 ? 'vanillia' :
                            this.activeTaste === 4 ? 'gold' : 'black';
        },


        currentTaste() {
            return this.tastes[this.activeTaste];
        },

        currentWord() {
            return this.round?.choice?.localised_messages;
        },

        showMiniIcons() {
            return this.step !== 'intro' && this.step !== 'cabinet'
        },
    },

    methods: {
        async startGame() {
            try {
                const response = await this.axios.post('/game/init');
                this.game = response.data.game;
                if (!this.token) {
                    this.token = response.data.token;
                    this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
                }
                this.initRound();
            } catch (e) {
                console.log(e)
            }
        },

        async initRound() {
            try {
                const response = await this.axios.post('/game/round/init', {
                    game: this.game.id
                });
                this.round = response.data.round;
                this.step = 'tastes';
            } catch (e) {
                console.log(e);
            }
        },

        async startRound() {
            if (window.isMobileOrTablet) {
                const app = document.querySelector('#app');
                const to = this.$refs.game.getBoundingClientRect().top + app.scrollTop;
                app.scrollTo({
                    top: to,
                    behavior: 'smooth',
                })
            }
            try {
                await this.axios.post('/game/round/start', {
                    round: this.round.id
                });
                this.step = 'drawing';
            } catch (e) {
                console.log(e);
            }
        },

        restartGame() {
            this.step = 'intro';
            this.game = {};
            this.round = null;
            this.activeTaste = 0;
            this.drawnTastes = 0;
            this.correctDrawings = 0;
            if (window.location.hostname !== 'localhost') {
                ga('send', 'event', 'click', 'game', 'replay');
                console.log(`ga('send', 'event', 'click', 'game','replay')`)
            }
        },

        drawCorrectly() {
            this.correctDrawings++;
            this.drawnTastes++;
            this.step = 'draw-result-correct';
        },

        drawWrong() {
            this.drawnTastes++;
            this.step = 'draw-result-wrong';
        },

        nextTaste() {
            if (this.activeTaste < 4) {
                this.initRound();
                setTimeout(() => {
                    this.activeTaste++;
                }, 300);
            } else {
                if (this.correctDrawings > this.user.result) {
                    this.user.result = this.correctDrawings;
                }
                this.step = 'cabinet';
                if (window.isMobileOrTablet) {
                    const app = document.querySelector('#app');
                    const to = this.$refs.game.getBoundingClientRect().top + app.scrollTop;
                    app.scrollTo({
                        top: to,
                        behavior: 'smooth',
                    })
                }
            }
        },
        async checkToken() {
            const token = this.$route.query.token;
            const gameId = this.$route.query.game;
            if (token && gameId) {
                try {
                    const response = await this.axios.get(`/game/${gameId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response?.data?.user) {
                        this.user = response?.data?.user;
                        this.game = response?.data?.user?.game;
                        this.user.result = response?.data?.user?.game?.answered;
                        this.$router.replace('/')
                        this.$emit('goToGame');
                        this.step = 'cabinet';
                        this.token = token;
                        this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                        this.$cookie.set('token', token);
                    }
                } catch (e) {
                    console.log(e)
                }
            } else {
                let tokenFromCookie = this.$cookie.get('token');
                if (tokenFromCookie) {
                    try {
                        const response = await this.axios.get(`/user`, {
                            headers: {
                                Authorization: `Bearer ${tokenFromCookie}`
                            }
                        });
                        if (response?.data?.user) {
                            this.user = response?.data?.user;
                            this.user.result = this.user.game?.answered;
                            this.game = this.user.game;
                            this.correctDrawings = this.user.result;
                            this.token = tokenFromCookie;
                            this.axios.defaults.headers.common.Authorization = `Bearer ${tokenFromCookie}`;
                            if (!this.user.bind) {
                                this.step = 'finish';
                            } else {
                                this.step = 'cabinet';
                            }
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        },

    },
};
</script>

<style scoped lang="scss">
.game {
    height: 100%;
    background: url(../../assets/img/game/luminescence/black.png) no-repeat center center / cover;
    transition: background-color 1s;

    &.black {
        background-color: rgba($black, 1);
    }

    &.blue {
        background-color: rgba(#29425d, 0.3);
    }

    &.gold {
        background-color: rgba($gold, 0.3);
    }

    &.pink {
        background-color: rgba(#521436, 0.3);
    }

    &.vanillia {
        background-color: rgba($vanillia, 0.3);
    }
}

.container {
    position: relative;
}

.screen {
    &-enter {
        opacity: 0;
    }

    &-enter-active {
        transition: .3s;
    }

    &-leave-active {
        opacity: 0;
        transition: .3s;
    }
}

.mini-cups {
    &-enter {
        &::v-deep {
            .cup {
                opacity: 0;
                transform: translateY(-50%);
            }
        }
    }

    &-enter-active {
        transition: 1.1s;

        &::v-deep {
            .cup {
                transition-duration: .3s;

                &:nth-of-type(1) {
                    transition-delay: .4s;
                }

                &:nth-of-type(2) {
                    transition-delay: .5s;
                }

                &:nth-of-type(3) {
                    transition-delay: .6s;
                }

                &:nth-of-type(4) {
                    transition-delay: .7s;
                }

                &:nth-of-type(5) {
                    transition-delay: .8s;
                }
            }
        }
    }

    &-leave-active {
        opacity: 0;
        transition: .3s;
    }
}
</style>
