export default {
    bind(el) {
        el.style.minHeight = window.innerHeight + 'px';
        window.addEventListener('resize', () => {
            el.style.minHeight = window.innerHeight + 'px';
            setTimeout(() => {
                window.scrollBy(0, 1);
            }, 1000)
        });
    }
}
