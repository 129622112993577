<template lang="pug">
    .tastes(:class="activeColor" ref="tastes")
        .container(v-min-height)
            div
                taste-number(:number="taste.number" :activeColor="activeColor")
                .center
                    span Нарисуйте
                    b(:class="{small: isSmallWord}" v-html="getSpanWord(currentWord[1])")
                    br
                    span за 20 секунд
            .bottom
                app-button(@click.native="startHandler") Рисуем
                .info
                    .name {{ taste.name }}
                        span  {{ taste.name2 }}
                    hr
                    .description(v-html="taste.description")
            white-shadow(data-parallax="-4:-4:0")
            white-circle
            cup.cup1(:activeColor="activeColor" data-parallax="-3:-2:3")
            cup.cup2(:activeColor="activeColor" data-parallax="-2:-2:2")
            pack(:activeColor="activeColor" data-parallax="-2:-1:-2")
</template>

<script>
import AppButton from "@/components/app/app-button/index";
import WhiteShadow from "@/components/game/decor/whiteShadow";
import WhiteCircle from "@/components/game/decor/whiteCircle";
import Cup from "@/components/game/decor/cup";
import TasteNumber from "@/components/game/other/tasteNumber";
import Pack from "@/components/game/decor/pack";
import parallax from "@/mixins/parallax";

export default {
    name: "tastes",
    components: {Pack, TasteNumber, Cup, WhiteCircle, WhiteShadow, AppButton},
    props: {
        activeColor: {
            required: true,
        },
        taste: {
            default: () => ({}),
            required: true
        },
        currentWord: {
            default: () => ([]),
            required: true
        }
    },

    mixins: [parallax],

    computed: {
        isSmallWord() {
            return this.currentWord?.[0]?.length > 10 || this.currentWord?.[1]?.length > 10;
        }
    },
    methods: {
        startHandler() {
            this.$emit('start');
            if (window.location.hostname !== 'localhost') {
                ga('send', 'event', 'click', 'step', this.taste.number);
                console.log(`ga('send', 'event', 'click', 'step',${this.taste.number})`);
            }
        },
        getSpanWord(word) {
            return word.split(' ').map(el => `<span class="gradient-text">${el}</span>`).join(' ')
        }
    },

    mounted() {
        if (!window.isMobileOrTablet) {
            this.initParallax(this.$refs['tastes']);
        }
    },
}
</script>

<style scoped lang="scss">
.tastes {
    overflow-x: hidden;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 9.2vh;
    padding-bottom: 15vh;
}

.taste-number {
    margin-bottom: 6.8vh;
}

.center {
    width: rem(560px);
    margin-bottom: 14vh;
    padding: 4.2rem 0 4.8rem 7.1rem;
    border: 1px solid rgba(#b89669, 0.2);
    border-radius: rem(6px);
    background: rgba(#000, 0.2);
    font-size: rem(30px);
    font-weight: 100;

    span:nth-of-type(1) {
        display: block;
        margin-bottom: 0.3rem;
    }

    b {
        font-size: rem(60px);
        line-height: 1.3;
        font-weight: 300;
        letter-spacing: 0.4rem;
        text-transform: uppercase;

        &.small {
            font-size: 3rem;
            line-height: 1.15;
            letter-spacing: 0.1rem;
        }
    }
}

.bottom {
    position: relative;

    .info {
        position: absolute;
        right: -1rem;
        top: -15.4vh;
        max-width: 36rem;
    }

    .name {
        font-size: rem(60px);
        letter-spacing: 0.1rem;

        span {
            text-transform: uppercase;
        }
    }

    hr {
        margin: 2.1rem 0 2.2rem;
    }

    .description {
        font-size: 1.5rem;
        line-height: 1.23;
        font-weight: 300;
    }
}

.whiteShadow {
    top: -6%;
    right: 0%;
    @include razr1024 {
        top: -2%;
        right: 0%;
    }
}

.whiteCircle {
    top: 13%;
    right: 10%;
    @include razr1024 {
        top: 15%;
        right: 12%;
    }
}

.cup1 {
    top: 16%;
    right: 37%;
    width: 15vh;
    height: 15vh;

    &::v-deep span {
        transform: rotate(2deg);
    }

    @include razr1024 {
        top: 16%;
        width: 9rem;
        height: 9rem;
    }
}

.cup2 {
    top: 43.8%;
    right: 0.3%;
    width: 13vh;
    height: 12.7vh;

    &::v-deep span {
        transform: rotate(138deg);
    }

    @include razr1024 {
        top: 42.8%;
        right: 3.3%;
        width: 9rem;
        height: 9rem;
    }
}

.pack {
    top: 20%;
    right: 1%;
    width: 72vh;
    height: 35vh;
    @include razr1024 {
        top: 21%;
        right: 2%;
        width: 44rem;
        height: 20rem;
    }
}

@include razr1280 {
    .cup1 {
        right: 43%;
    }
}

@include laptop {
    .container {
        padding-bottom: 11vh;
    }
    .center {
        margin-bottom: 9vh;
        padding: 3.2rem 0 3.8rem 7.1rem;
    }
    .bottom hr {
        margin: 1.1rem 0 1.2rem;
    }
    .cup1 {
        right: 34%;
    }
    .cup2 {
        right: 3.3%;
    }
    .pack {
        right: 4%;
    }
}

@media screen and (max-height: 530px) and (width: 1280px) {
    .container {
        padding-bottom: 10vh;
    }
    .bottom .info {
        top: -19.4vh;
    }
}

@include mobile_or_P {
    .container {
        justify-content: space-between;
        align-items: center;
        padding-top: 9.4rem;
        padding-bottom: 3.5rem;
    }
    .taste-number {
        margin-bottom: 2rem;
    }
    .center {
        width: 77.5vw;
        margin-bottom: 23.3rem;
        padding: 1.2rem 0 1rem;
        border-radius: 1rem;
        font-size: 1.6rem;
        text-align: center;

        b {
            font-size: 3.5rem;
        }
    }
    .bottom {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        .info {
            position: static;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 100vmin;
            margin-bottom: 3.85rem;
        }

        .name {
            font-size: 2.75rem;
        }

        hr {
            margin: 1.4rem 0 1.2rem;
        }

        .description {
            font-size: 1.62rem;
            text-align: center;
        }
    }
    .whiteShadow {
        top: 49%;
        right: 47%;
        transform: translate(50%, -50%);
        width: 27rem;
        height: 27rem;
    }
    .whiteCircle {
        top: 50.2%;
        right: 50%;
        transform: translate(50%, -50%);
        width: 17.5rem;
        height: 17.5rem;
    }
    .cup1 {
        top: calc(41% - 3rem);
        right: calc(50% + 4.5rem);
        width: 6rem;
        height: 6rem;
    }
    .cup2 {
        top: calc(56.5% - 2rem);
        right: calc(50% - 10.8rem);
        width: 4rem;
        height: 4rem;

        &::v-deep span {
            transform: rotate(124deg);
        }
    }
    .pack {
        top: 50.5%;
        right: 51%;
        width: 29rem;
        height: 14rem;
        transform: translate(50%, -50%);
    }
}
</style>
