<template lang="pug">
    .intro(ref="intro")
        .container(v-min-height)
            .intro__top
                img.intro__logo(src="@/assets/img/logo.png")
                span(v-html="'Преобразуйте реальность вместе&nbsp;с&nbsp;капсулами кофе&nbsp;Jardin!'")
            .intro__center
                img.intro__title(src="@/assets/img/intro_title.png")
                cup(activeColor="black" data-parallax="-3:-4:0")
                cup(activeColor="blue" data-parallax="-2:2:0")
                cup(activeColor="pink" data-parallax="4:-3:0")
                cup(activeColor="vanillia" data-parallax="-3:-1:0")
                cup(activeColor="gold" data-parallax="2:-5:0")
            .intro__bottom
                //.left
                    white-circle
                    white-shadow( data-parallax="-3:-3:0")
                    img.jbl(src="@/assets/img/jbl.png")
                .right
                    hr
                    p(v-html="'Покажите свое мастерство рисования'")
        .scroll-down
            span(@click="$emit('goToConditions')") Листайте вниз
            img.mouse(src="@/assets/img/scroll_down.png" @click="$emit('goToConditions')")
            img.arrow(src="@/assets/img/icons/arrow_bottom.png" @click="$emit('goToConditions')")
</template>

<script>
import WhiteCircle from "@/components/game/decor/whiteCircle";
import Cup from "@/components/game/decor/cup";
import WhiteShadow from "@/components/game/decor/whiteShadow";
import parallax from "@/mixins/parallax";

export default {
    name: "intro",

    components: {WhiteShadow, Cup, WhiteCircle},

    mixins: [parallax],

    mounted() {
        if (!window.isMobileOrTablet) {
            this.initParallax(this.$refs.intro);
        }
    },
}
</script>

<style scoped lang="scss">
.intro {
    position: relative;
    height: 100%;
    background: url(../../assets/img/intro_bg.png) no-repeat 5% bottom / auto 65%;

    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding-top: 9.5vh;
        padding-bottom: 12vh;
        @include laptop {
            padding-top: 7.5vh;
            padding-bottom: 7vh;
        }
    }

    &__logo {
        position: absolute;
        top: 8vh;
        left: 6.5rem;
        width: 9.4rem;
    }

    &__top {
        max-width: 47rem;
        font-size: rem(30px);
        line-height: 1.65;
        letter-spacing: 0.34rem;
        text-transform: uppercase;
        text-align: center;
        color: #b89669;
    }

    &__center {
        position: relative;

        .cup {
            width: 6.3rem;
            height: 6.3rem;

            &.black {
                top: -4.5rem;
                left: -13.8%;

                &::v-deep span {
                    transform: rotate(30deg);
                }
            }

            &.blue {
                top: 7rem;
                left: 21%;
                width: 5.8rem;
                height: 5.8rem;
                @include laptop {
                    top: 6rem;
                }

                &::v-deep span {
                    transform: rotate(177deg);
                }
            }

            &.pink {
                top: 11rem;
                right: 18%;
                @include laptop {
                    top: 7rem;
                }
            }

            &.vanillia {
                top: -6.5rem;
                left: 56%;

                &::v-deep span {
                    transform: rotate(90deg);
                }
            }

            &.gold {
                top: -1.5rem;
                right: -13%;
                width: 5.3rem;
                height: 5.3rem;

                &::v-deep span {
                    transform: rotate(85deg);
                }
            }
        }
    }

    &__title {
        max-width: 62rem;
        @include laptop {
            max-width: 53rem;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .left {
            position: relative;
            display: flex;
            justify-content: center;
            width: calc(83% - 35rem);
            padding-left: 4rem;

            .whiteCircle {
                position: static;
                width: rem(230px);
                height: rem(230px);
            }

            .whiteShadow {
                width: 23rem;
                height: 23rem;
                top: -32%;
                left: 25%;
            }

            .jbl {
                position: absolute;
                top: 50%;
                left: 52%;
                transform: translate(-50%, -50%);
                width: rem(360px);
                transition: transform .3s;
                &:hover {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }

        .right {
            width: 35rem;
            padding-bottom: 0.8rem;

            hr {
                margin: 0 0 1.2rem;
            }

            p {
                font-size: rem(30px);
                line-height: 1.35;
            }
        }
    }

    .scroll-down {
        position: absolute;
        bottom: 13.3vh;
        left: calc((100vw - 99rem) / 2 * 0.67);
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #b89669;
        .arrow {
            display: none;
        }
        span {
            cursor: pointer;
        }

        img {
            width: rem(21px);
            margin-top: 0.7rem;
            cursor: pointer;
        }
        @include razr1600 {
            left: calc((100vw - 99rem) / 2 + 1rem);
        }
        @include razr1536 {
            left: 5rem;
        }
    }

    @include mobile_or_P {
        background: url(../../assets/img/intro_bg-mobile.png) no-repeat center bottom / 100% auto;
        .container {
            padding-top: 2.3rem;
            padding-bottom: 5.9rem;
        }

        &__logo {
            position: static;
            width: 13.3rem;
            margin-bottom: 1.5rem;
        }

        &__top {
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 1.3;
        }

        &__center {
            margin: 9rem 0 7rem;

            .cup {
                width: 4.3rem;
                height: 4.3rem;

                &.black {
                    top: -6rem;
                    left: 4%;
                }

                &.blue {
                    top: 2.4rem;
                    left: 19.5%;
                    width: 3.8rem;
                    height: 5.8rem;
                }

                &.pink {
                    top: 6.5rem;
                    right: 22.5%;
                }

                &.vanillia {
                    top: -7rem;
                    left: 47%;
                }

                &.gold {
                    top: -4.7rem;
                    right: 7%;
                    width: 3.8rem;
                    height: 3.8rem;

                    &::v-deep span {
                        transform: rotate(30deg);
                    }
                }
            }
        }
        &__title {
            max-width: 33.5rem;
        }
        &__bottom {
            flex-direction: column;
            align-items: center;

            .left {
                width: 100%;
                margin-bottom: 1.5rem;
                padding-left: 0;

                .whiteCircle {
                    width: 14rem;
                    height: 14rem;
                }

                .whiteShadow {
                    width: 22rem;
                    height: 22rem;
                    top: -33%;
                    left: 24%;
                }

                .jbl {
                    left: 47%;
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                align-items: center;

                hr {
                    margin: 0 0 1rem;
                }

                p {
                    font-size: 1.6rem;
                    line-height: 1.3;
                    text-align: center;
                }
            }
        }
    }
}
.isMobileOrTablet .intro {
    .scroll-down {
        left: 50%;
        bottom: 2.2rem;
        transform: translateX(-50%);
        font-size: 0;
        .arrow {
            display: block;
        }
        .mouse {
            display: none;
        }

        img {
            width: 2.5rem;
        }
    }

}
</style>
