<template lang="pug">
    .finish
        .container(v-min-height)
            .left
                div
                    h3 Поздравляем!
                    h4 Вы набрали {{ correctDrawings }} из 5 баллов
                p(v-html="'Для подтверждения результата —&nbsp;зарегистрируйтесь'")
                form(@submit.prevent="submit")
                    app-input(
                        v-model="first_name"
                        placeholder="Ваше имя"
                        :error="$v.$dirty && !$v.first_name.required ? 'Поле обязательно для заполнения' : ''")
                    app-input(
                        v-model="last_name"
                        placeholder="Ваша фамилия"
                        :error="$v.$dirty && !$v.last_name.required ? 'Поле обязательно для заполнения' : ''")
                    app-input(
                        v-model="email"
                        placeholder="Ваш e-mail"
                        :error="$v.$dirty && !$v.email.required ? 'Поле обязательно для заполнения' : $v.$dirty && !$v.email.email ? 'Некорректный формат E-mail' : ''")
                    app-checkbox(
                        v-model="pdnAgree"
                        :error="$v.$dirty && !$v.pdnAgree.checked")
                        span(v-html="'С&nbsp;'")
                        a(href="/files/rules.pdf" target="_blank" @click.stop="") правилами акции
                        span(v-html="'&nbsp;согласен'")
                    app-button(tag="button" type="submit" :class="{disabled: isDisabledButton}") Регистрация
            //.right
                h3.gradient-text(v-html="'Вы можете выиграть&nbsp;эти призы!'")
                .img
        transition(name="success-popup")
            success-popup(v-if="showSuccessPopup"
                :email="email"
                @close="closeSuccessPopup")
</template>

<script>
import AppInput from "@/components/app/app-input/index";
import AppCheckbox from "@/components/app/app-checkbox/index";
import AppButton from "@/components/app/app-button/index";
import SuccessPopup from "@/components/game/other/SuccessPopup";
import {required, email} from 'vuelidate/lib/validators'

export default {
    name: "Finish",
    components: {SuccessPopup, AppButton, AppCheckbox, AppInput},
    props: {
        correctDrawings: {
            required: true,
        },
        game: {
            required: true,
        },
        user: {},
        token: {},
    },
    data: () => ({
        first_name: '',
        last_name: '',
        email: '',
        pdnAgree: false,

        showSuccessPopup: false,
    }),

    watch: {
        first_name(value) {
            this.first_name = this.validateCyrillic(value);
        },
        last_name(value) {
            this.last_name = this.validateCyrillic(value);
        },
    },

    validations: {
        first_name: {required},
        last_name: {required},
        email: {required, email},
        pdnAgree: {
            checked(value) {
                return value
            }
        },
    },

    computed: {
        isDisabledButton() {
            return !this.first_name || !this.last_name || !this.email || !this.pdnAgree;
        }
    },

    methods: {
        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch()
                return
            }
            try {
                await this.axios.post('/game/bind', {
                    game: this.game.id,
                    firstName: this.first_name,
                    lastName: this.last_name,
                    email: this.email
                });
                this.$cookie.set('token', this.token);
                this.showSuccessPopup = true;

                if (window.location.hostname !== 'localhost') {
                    ga('send', 'event', 'send', 'from', 'register');
                    console.log("ga('send', 'event', 'send', 'from', 'register')");
                }

            } catch (e) {
                console.log(e)
            }
        },

        closeSuccessPopup() {
            this.showSuccessPopup = false;
        },

        validateCyrillic(value) {
            return value.replace(/[^\u0400-\u04ffa-zA-Z\s-]/g, '');
        },
    },

    beforeMount() {
        if (this.user) {
            this.first_name = this.user?.firstName || '';
            this.last_name = this.user?.lastName || '';
            this.email = this.user?.email || '';
            if (!this.user.bind) {
                this.showSuccessPopup = true;
            }
        }
    }
}
</script>

<style scoped lang="scss">
.finish {
    position: relative;
    background: url(../../assets/img/game/finish_jardin_bg.png) no-repeat right bottom / auto 100% #000;
}

.container {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 21.6vh;
    padding-bottom: 16vh;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

h3 {
    margin-bottom: 1.6vh;
    font-size: rem(30px);
    letter-spacing: 0.39rem;
    text-transform: uppercase;
}

h4 {
    margin-bottom: 1.2vh;
    font-size: rem(36px);
    font-weight: bold;
}

p {
    max-width: 23rem;
    font-size: 1.5rem;
    line-height: 1.23;
    font-weight: 300;
}

form {
    width: 27.6rem;

    .app-input {
        margin-bottom: 3.7vh;
    }

    .app-checkbox {
        margin: 4vh 0 5.4vh;
    }

    a {
        position: relative;
        display: inline-flex;
        white-space: nowrap;
        transition: color .2s;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            background: #fff;
            transition: background-color .2s;
        }

        &:hover {
            color: #947e59;

            &:after {
                background: #947e59;
            }
        }
    }
}

.right {
    align-self: flex-start;
    position: relative;
    padding-right: 5rem;

    .img {
        position: absolute;
        top: calc(100% + 4.5vh);
        left: 35%;
        transform: translateX(-50%);
        width: 81vh;
        height: 52.5vh;
        background: url(../../assets/img/game/finish_jardin.png) no-repeat center top / contain;
        @include razr1024 {
            width: 50rem;
            height: 31rem;
        }
    }
}

.success-popup-enter {
    background: transparent;

    &::v-deep {
        .popup__inner {
            opacity: 0;
        }
    }
}

.success-popup-enter-active {
    transition: background-color .2s, all .4s;

    &::v-deep {
        .popup__inner {
            transition: .4s;
        }
    }
}

.success-popup-leave-active {
    opacity: 0;
    transition: .3s;
}

@include razr1600 {
    .container {
        padding-bottom: 10vh;
    }
}

@include laptop {
    .container {
        padding-top: 20.6vh;
    }
}

@include mobile_or_P {
    .container {
        flex-direction: column;
        padding-top: 7.9rem;
        padding-bottom: 3.5rem;
    }
    .left {
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 2.8rem;
        text-align: center;
    }
    h3 {
        margin-bottom: 0.75rem;
    }
    h4 {
        margin-bottom: 0.6rem;
        font-size: 1.9rem;
    }
    p {
        max-width: 80%;
        margin-bottom: 1rem;
        font-size: 1.63rem;
        line-height: 1.25;
    }
    form {
        width: 33.5rem;

        .app-input {
            margin-bottom: 1.3rem;
        }

        .app-checkbox {
            margin: 1.8rem 0 2.7rem;
        }
    }
    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-right: 0;

        h3 {
            max-width: 80%;
            margin: 0 auto 2.6rem;
            line-height: 1.4;
            text-align: center;
        }

        .img {
            position: static;
            width: 29.2rem;
            height: 17rem;
            transform: translateX(-12%);
            background-image: url(../../assets/img/game/finish_jardin-mobile.png);
        }
    }

}
</style>
