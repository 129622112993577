<template lang="pug">
    .drawing(:class="activeColor" ref="drawing")
        .container(v-min-height)
            .top Нарисуйте
                b(:class="{small: isSmallWord}") {{ currentWord[1] }}
            timer(:activeColor="activeColor" :duration="duration")
            .corn
                img(src="@/assets/img/game/corn.png")
                corn-answer(v-if="version"
                    :activeColor="activeColor"
                    :version-text="versionText"
                    :version="version + versionEnding")
            .closer(@click="$emit('close')")
            draw-place(:activeColor="activeColor"
                :roundId="roundId"
                @checkDrawingResult="checkDrawingResult")

</template>

<script>
import Timer from "@/components/game/other/timer";
import CornAnswer from "@/components/game/other/cornAnswer";
import DrawPlace from "@/components/game/other/drawPlace";

export default {
    name: "drawing",
    components: {DrawPlace, CornAnswer, Timer},
    props: {
        currentWord: {
            required: true,
        },
        roundId: {
            required: true,
        },
        activeColor: {
            required: true,
        }
    },
    data: () => ({
        duration: 20,
        version: null,
        versionNumber: 0,
        correctly: false,
    }),
    computed: {
        versionText() {
            if (this.correctly) {
                return 'Да это же '
            }
            if (this.versionNumber === 1) {
                return 'Может, это '
            }
            return 'Или '
        },

        versionEnding() {
            return this.correctly ? '!' : '?';
        },

        isSmallWord() {
            return this.currentWord?.[0]?.length > 10 || this.currentWord?.[1]?.length > 10;
        }
    },
    methods: {
        checkDrawingResult(data) {
            if (data.move?.winning) {
                this.correctly = true;
            }
            this.setVersion(data.move);
            if (data.move?.winning) {
                clearTimeout(window.loseTimeout);
                setTimeout(() => {
                    this.$emit('correctly');
                }, 2000);
            }
        },

        setVersion(version) {
            const versionText = version.localised_messages?.[0];
            const versionAudio = version.audio;
            if (this.version === versionText) return;
            this.versionNumber++;
            this.version = versionText;

            const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
                const byteCharacters = atob(b64Data);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);

                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                return new Blob(byteArrays, {type: contentType});
            }

            const blob = b64toBlob(versionAudio.binary, versionAudio['content-type']);
            const blobUrl = URL.createObjectURL(blob);

            document.getElementById('versionAudio').src = blobUrl;
            document.getElementById('versionAudio').play();
        }
    },

    mounted() {
        window.loseTimeout = setTimeout(() => {
            this.$emit('wrong');
        }, this.duration * 1000)
    },

    beforeDestroy() {
        clearTimeout(window.loseTimeout);
    }
}
</script>

<style scoped lang="scss">
.drawing {
    min-height: 69rem;
    background: url(../../assets/img/game/cup_2.png) no-repeat 93.8% 84.5%/auto 31.8%;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 9vh;
    transform: translate3d(0,0,0);
}

.top {
    margin-bottom: 4.3vh;
    font-size: rem(30px);
    font-weight: 100;

    b {
        display: block;
        max-width: 22rem;
        font-size: rem(60px);
        line-height: 1.25;
        font-weight: 300;
        text-transform: uppercase;

        &.small {
            margin-top: 0.5rem;
            font-size: 2.5rem;
            line-height: 1;
        }
    }
}

.timer {
    margin-bottom: 17.8vh;
    transform: translateX(-0.6rem);
}

.corn {
    position: relative;
    z-index: 10;
    transform: translateX(-45%);

    &-answer {
        position: absolute;
        z-index: 10;
        bottom: calc(100% + 1.35rem);
        left: 48%;
    }

    img {
        height: rem(65px);
        opacity: 0;
    }
}

.closer {
    position: absolute;
    z-index: 1;
    top: 5vh;
    right: 6.5rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: $white;
    box-shadow: 0 0 1.5rem #fff;
    cursor: pointer;
    transition: .2s;

    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40%;
        height: 2px;
        background: $black;
    }

    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover {
        box-shadow: 0 0 1.85rem #fff;
    }
}

.draw-place {
    position: absolute;
    z-index: -1;
    top: 55%;
    left: 51.5%;
    transform: translate(-50%, -50%);
    @include razr1024 {
        left: 55.5%;
    }

    @media screen and (min-height: 800px) and (width: 1280px) {
        top: 50%;
        left: 55.5%;
    }
}

@media screen and (max-height: 530px) and (width: 1280px) {
    .container {
        padding-top: 13vh;
    }
    .top {
        margin-bottom: 1.3vh;
    }
    .timer {
        margin-bottom: 15.8vh;
    }
    .corn {
        &-answer {
            bottom: calc(100% + 0.35rem);
        }
    }
}

@include mobile_or_P {
    .container {
        justify-content: flex-start;
        align-items: center;
        padding-top: 8.75rem;
    }
    .top {
        font-size: 1.6em;
        text-align: center;

        b {
            font-size: 2.75rem;
            line-height: 1.5;
        }
    }
    .timer {
        position: absolute;
        top: 7.8rem;
        left: calc((100vw - 100vmin) / 2 + 3.5rem);
    }
    .corn {
        position: absolute;
        top: 61.5rem;
        left: calc(50% - 16.4rem);
        transform: initial;

        &-answer {
            bottom: calc(100% + 0.75rem);
            padding: 1.55rem 2rem 1.55rem 1.7rem;
            font-size: 2.45rem;

            max-width: 76vw;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        img {
            height: 3.1rem;
        }
    }
    .closer {
        top: 2.25rem;
        right: 2rem;
        width: 3.6rem;
        height: 3.6rem;

        &:before, &:after {
            height: 1px;
        }
    }
    .draw-place {
        top: 17.4rem;
        left: 50%;
        transform: translateX(-45%);
    }
}
</style>
