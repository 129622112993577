<template lang="pug">
    .whiteCircle
</template>

<script>
export default {
    name: "whiteCircle"
}
</script>

<style scoped lang="scss">
.whiteCircle {
    position: absolute;
    width: 46vh;
    height: 46vh;
    background: #fff;
    border-radius: 50%;
    @include razr1024 {
        width: 27rem;
        height: 27rem;
    }
    @include mobile_or_P{
        width: 22rem;
        height: 22rem;
    }
}
</style>
