<template lang="pug">
    .ieZaglushka
        .header
            img(src="@/assets/img//ieZaglushka/logo_h_green.svg" alt="logo")
        h2 Ваш браузер устарел!
        p Вы&nbsp;пользуетесь устаревшей версией браузера Internet Explorer.<br>
            | Данная версия браузера не&nbsp;поддерживает многие настройки и&nbsp;технологические решения, из-за чего страницы и&nbsp;функции могут работать не&nbsp;корректно.
            | Бесплатные версии подходящих браузеров доступны для скачивания:
        .list
            .item
                img(src="@/assets/img/ieZaglushka/firefox.png" alt="")
                h6 Mozilla Firefox
                small Доступно для:
                .platforms
                    img(src="@/assets/img/ieZaglushka/windows.png" alt="")
                    img(src="@/assets/img/ieZaglushka/apple.png" alt="")
                    img(src="@/assets/img/ieZaglushka/linux.png" alt="")
                app-button(href="https://www.mozilla.org/" tag="a" target="_blank") Перейти к загрузке
            .item
                img(src="@/assets/img/ieZaglushka/chrome.png" alt="")
                h6 Google Chrome
                small Доступно для:
                .platforms
                    img(src="@/assets/img/ieZaglushka/windows.png" alt="")
                    img(src="@/assets/img/ieZaglushka/apple.png" alt="")
                    img(src="@/assets/img/ieZaglushka/linux.png" alt="")
                app-button(href="https://www.google.com/chrome/" tag="a" target="_blank") Перейти к загрузке
            .item
                img(src="@/assets/img/ieZaglushka/opera.png" alt="")
                h6 Opera
                small Доступно для:
                .platforms
                    img(src="@/assets/img/ieZaglushka/windows.png" alt="")
                    img(src="@/assets/img/ieZaglushka/apple.png" alt="")
                    img(src="@/assets/img/ieZaglushka/linux.png" alt="")
                app-button(href="https://www.opera.com/ru" tag="a" target="_blank") Перейти к загрузке
            .item
                img(src="@/assets/img/ieZaglushka/safari.png" alt="")
                h6 Safari
                small Доступно для:
                .platforms
                    img(src="@/assets/img/ieZaglushka/apple.png" alt="")
                app-button(href="https://www.apple.com/" tag="a" target="_blank") Перейти к загрузке
</template>

<script>
import AppButton from "@/components/app/app-button/index";
export default {
    name: "IEZaglushka",
    components: {AppButton}
}
</script>

<style scoped lang="scss">

.ieZaglushka {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    z-index: 10000000;
    color: $gold;
    background: url(../../assets/img/intro_bg.png) no-repeat 5% bottom / auto 65% #000;
    overflow: hidden;
    text-align: center;

    .header {
        width: 100%;
        margin-bottom: 3.6vh;
        padding: 1.5rem 3rem 0;
        text-align: left;
        opacity: 0;
        pointer-events: none;

        img {
            width: 11.7rem;
        }
    }

    h2 {
        margin-bottom: 2.3rem;
        font-size: rem(60px);
        font-weight: 900;
        letter-spacing: 0.26rem;
    }

    p {
        max-width: 62rem;
        margin: 0 auto 5.7vh;
        font-size: 1.25rem;
        line-height: 1.4;
        text-align: center;
    }

    .list {
        display: block;

        .item {
            display: inline-block;
            width: 18.3rem;
            margin: 0 0.6rem;
            padding: 3.6vh 1.5rem 4.4vh;
            border: 1px solid #d6d6d6;
            border-radius: 0.5rem;
            text-align: center;

            & > img {
                width: 4.6rem;
                margin-bottom: 4.2vh;
            }

            h6 {
                margin-bottom: 2.3vh;
                font-size: 1.25rem;
                font-weight: 700;
            }

            small {
                display: block;
                margin-bottom: 1rem;
                font-size: rem(13px);
                font-weight: 600;
            }

            .platforms {
                margin-bottom: 3vh;

                img {
                    width: 1.4rem;
                    margin: 0 0.7rem;
                }
            }
            .button {

                background: linear-gradient(45deg, #9d8a52 0%, #a38a61 50%, #cec2ab 80%, #d0bfa2 100%);
                &, &::v-deep .hover {
                    width: 100%;
                    min-width: 100%;
                    padding: 0;
                    justify-content: center;
                    letter-spacing: 0.1rem;
                }
                &::v-deep .hover {
                    background: #000;
                    opacity: 0;
                }

                &:hover {
                    &::v-deep .hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @include laptop {
        header {
            margin-bottom: 1vh;
            padding: 1rem 2rem 0;

            img {
                width: 9.7rem;
            }
        }
        h2 {
            margin-bottom: 1rem;
            font-size: 2.75rem;
            letter-spacing: 0.2rem;
        }
        p {
            line-height: 1.5;
        }
        .list {
            .item {
                width: 16.3rem;
                margin: 0 0.6rem;
                padding: 1rem 2rem 1rem;

                & > img {
                    width: 4rem;
                    margin-bottom: 1rem;
                }

                h6 {
                    margin-bottom: 1rem;
                }
            }
        }
    }
    display: none;
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        display: block;
    }
    @media screen and (min-width: 0 \0
    ) and (min-resolution: .001dpcm) {
        display: block;
    }
}
</style>
