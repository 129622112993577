<template lang="pug">
    .app-input(:class="classes")
        input(v-model="state"
            autocomplete="new-password"
            v-bind="attributes")
        .app-input__error(v-if="error" v-html="error")
</template>

<script>
export default {
    name: "app-input",

    props: {
        value: {
            type: [Number, String, Object],
            required: true,
        },

        error: {
            type: String,
            default: '',
        },
    },

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        classes() {
            return {
                'app-input--invalid': this.error,
            };
        },

        attributes() {
            return this.$attrs;
        },
    }
}
</script>

<style scoped lang="scss">
.app-input {
    position: relative;
    width: 100%;
    height: 2.75rem;
    border-bottom: 1px solid #b89669;
    transition: .3s;

    input {
        width: 100%;
        height: 100%;
        padding: 0;
        font-family: Circe, sans-serif;
        font-size: rem(30px);
        font-weight: 300;
        color: rgba(#fff, 1);

        &::-webkit-input-placeholder {
            color: rgba(#fff, 0.5);
        }

        &::-moz-placeholder {
            color: rgba(#fff, 0.5);
        }

        &:-moz-placeholder {
            color: rgba(#fff, 0.5);
        }

        &:-ms-input-placeholder {
            color: rgba(#fff, 0.5);
        }
    }

    &__error {
        position: absolute;
        top: calc(100% + 0.5rem);
        right: 0;
        font-size: rem(18px);
        font-weight: 300;
        color: $red;
    }

    &--invalid {
        border-color: $red;
    }
}

@include mobile_or_P {
    .app-input {
        height: 2.5rem;
        input{
            font-size: 1.6rem;
        }
        &__error{
            top: calc(100% + 0.4rem);
            font-size: 1.25rem;
        }
    }
}
</style>
