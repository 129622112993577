<template lang="pug">
    .sharing(ref="profile_sharing")
        .icon(@click="share('vk')")
            img(src="@/assets/img/icons/socials/vk.png")
        .icon(@click="share('ok')")
            img(src="@/assets/img/icons/socials/ok.png")
        .icon(@click="share('fb')")
            img(src="@/assets/img/icons/socials/fb.png")

        social-sharing(inline-template
        :url="url"
            :title="title"
            :description="description"
            :hashtags="hashtags"
            network-tag="a")
            .socials
                network.fb(network="facebook")
                    i(class="fa fa-facebook")
                network.vk(network="vk")
                    i(class="fa fa-vk")
                network.ok(network="odnoklassniki")
                    i(class="fa fa-vk")
</template>

<script>
export default {
    name: "Sharing",

    props: {
        result: {
            required: true,
        },
        game: {
            required: true,
        }
    },

    data: () => ({
        description: 'Призываю тебя переходить по ссылке и участвовать в конкурсе',
        hashtags: 'jardinart',
    }),

    computed: {
        title() {
            if (this.result === 0) {
                return `Вместе с Jardin я набрал 0 баллов из 5`;
            } else if (this.result === 1) {
                return `Вместе с Jardin я набрал 1 балл из 5`;
            } else if (this.result === 5) {
                return `Вместе с Jardin я нашел все вкусы их капсул!`;
            } else {
                return `Вместе с Jardin я набрал ${this.result} балла из 5`;
            }
        },

        url() {
            return `${this.hostname}/share.php/?title=${this.title}&description=${this.description}`;
        },

        hostname() {
            return window.location.hostname === 'localhost' ? 'https://jardin.dev-demo.online' : window.location.origin;
        }
    },

    methods: {
        async share(soc) {
            this.$refs.profile_sharing.querySelector(`.socials .${soc}`).click();
            if (window.location.hostname !== 'localhost') {
                ga('send', 'event', 'like', 'social', soc);
                console.log(`ga('send', 'event', 'like', 'social',${soc})`)
            }
            try {
                await this.axios.post(`/game/share`, {
                    game: this.game.id
                });
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.sharing {
    display: flex;

    .icon {
        width: rem(71px);
        height: rem(71px);
        margin-right: rem(46px);
        cursor: pointer;
        border-radius: 50%;
        transition: .2s;
        &:hover {
            box-shadow: 0 0 1.4rem 0.1rem $gold;
        }

        img {
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }

    @include mobile_or_P {
        justify-content: center;
        .icon {
            width: 5.5rem;
            height: 5.5rem;
            margin: 0 1.75rem;
        }
    }
}
</style>
