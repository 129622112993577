<template lang="pug">
    .cabinet
        .container(v-min-height)
            .left
                div
                    p Приветствуем!
                    h6.gradient-text У вас набрано {{ result }} из 5 баллов
                div
                    hr
                    h2(v-html="title")
                div
                    hr.forDesktop
                    p(v-html="subtitle")
                    app-button(@click.native="$emit('restart')") Переиграть
            //.right
                h4
                    span.gradient-text Получите
                    br
                    span.gradient-text дополнительные призы!
                .prizes
                    .prize
                        .img
                            img(src="@/assets/img/prizes/jbl_go_2.png")
                            white-circle
                            white-shadow
                        span(v-html="'Колонка JBL&nbsp;GO&nbsp;2'")
                    .prize
                        .img
                            img(src="@/assets/img/prizes/ozon.png")
                            white-circle
                            white-shadow
                        span Сертификат ОЗОН <br> 3000 рублей
                    .prize
                        .img
                            img(src="@/assets/img/prizes/true_wireless_jbl.png")
                            white-circle
                            white-shadow
                        span(v-html="'Наушники True&nbsp;Wireless <br>JBL T100 TWS'")
                div
                    p(v-html="'Станьте участником розыгрыша — <br> поделитесь результатом игры и&nbsp;добавьте&nbsp;хэштег'")
                    .hashtag.gradient-text #JARDINART
                    sharing(:result="result" :game="game")

</template>

<script>
import AppButton from "@/components/app/app-button/index";
import WhiteCircle from "@/components/game/decor/whiteCircle";
import WhiteShadow from "@/components/game/decor/whiteShadow";
import Sharing from "@/components/game/other/Sharing";

export default {
    name: "Cabinet",
    components: {Sharing, WhiteShadow, WhiteCircle, AppButton},

    props: {
        user: {
            required: true
        },
        game: {
            required: true
        }
    },

    computed: {
        result() {
            return this.user?.result;
        },

        first_name() {
            return this.user?.firstName;
        },

        title() {
            return this.result === 5 ? 'Вы набрали максимальный балл, поздравляем!' : 'Почти максимальный балл, поздравляем!';
        },

        subtitle() {
            return this.result === 5 ? 'Играйте еще раз, чтобы нарисовать все&nbsp;вкусы&nbsp;Jardin' : 'Играйте еще раз и набирайте <br> максимум&nbsp;баллов';
        },

    },
}
</script>

<style scoped lang="scss">
.cabinet {
    position: relative;
    background: url(../../assets/img/game/cabinet_bg.png) no-repeat right center / auto 67% #000;
}

.container {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.left, .right {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-height: inherit;
    padding-top: 16.9vh;
    padding-bottom: 16vh;
}

p {
    font-size: 1.5rem;
    line-height: 1.7;
    font-weight: 300;
}

h6 {
    font-size: rem(36px);
    font-weight: bold;
}

hr {
    margin: 0 0 1.6rem;
}

h2 {
    margin-bottom: -3vh;
    font-size: rem(60px);
    line-height: 1.2;
    font-weight: bold;
}

.button {
    margin-top: 3.75vh;
}

h4 {
    margin-top: -0.7vh;
    font-size: rem(30px);
    line-height: 1.5;
    letter-spacing: 0.375rem;
    text-indent: -0.2rem;
    text-transform: uppercase;
}

.prizes {
    display: flex;
    margin-bottom: -1.5vh;

    .prize {
        margin-right: rem(19px);

        .img {
            position: relative;
            width: rem(172px);
            height: rem(172px);
            margin-bottom: 1.25rem;

            img {
                position: absolute;
                z-index: 1;
                transform: translate(-50%, -50%);
                user-select: none;
                transition: transform .3s;
            }

            .whiteCircle {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .whiteShadow {
                position: absolute;
                top: 42%;
                left: 57%;
                transform: translate(-50%, -50%);
                width: 150%;
                height: 150%;
            }

            &:hover img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }

        span {
            display: block;
            width: 100%;
            line-height: 1.25;
            font-weight: 300;
            text-align: center;
        }

        &:nth-of-type(1) .img img {
            top: 55%;
            left: 49%;
            width: 9.3rem;
        }

        &:nth-of-type(2) .img img {
            top: 50%;
            left: 51%;
            width: 8.5rem;
        }

        &:nth-of-type(3) .img img {
            top: 50%;
            left: 48%;
            width: 8.2rem;
        }
    }
}

.hashtag {
    margin: 1vh 0 3.7vh;
    font-size: rem(30px);
    font-weight: bold;
}

.left {
    position: relative;
    width: 50%;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
        background: url(../../assets/img/game/cabinet_divider.png) no-repeat center center / 100% auto;
    }

}

.right {
    width: 45%;
    max-width: 35rem;
}

@include mobile_or_P {
    .cabinet {
        background: url(../../assets/img/game/cabinet_bg-mobile.png) no-repeat center center / 100% auto #000;
    }
    .container {
        flex-direction: column;
        padding-top: 2.8rem;
        padding-bottom: 3rem;
    }
    .left, .right {
        min-height: initial;
        padding-top: 0;
        padding-bottom: 0;
    }
    .left {
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
        text-align: center;

        &:after {
            display: none;
        }
    }
    .right {
        align-items: center;
        width: 100%;
        max-width: 100%;
        text-align: center;
    }
    p {
        br {
            display: none;
        }

        font-size: 1.9rem;
        line-height: 1.25;
    }
    hr {
        margin: 1.5rem auto 1.4rem;
    }
    h6 {
        font-size: 1.87rem;
    }
    h2 {
        margin-bottom: 0.2rem;
        font-size: 1.9rem;
    }
    h4 {
        margin-top: 0;
        margin-bottom: 2.3rem;
        font-size: 1.85rem;
        line-height: 1.4;
        letter-spacing: 0.2rem;
        text-indent: 0;
    }
    .button {
        margin-top: 1.8rem;
    }
    .prizes {
        justify-content: center;
        margin-bottom: 1.3rem;

        .prize {
            width: 33%;
            margin: 0;

            .img {
                width: 9.2rem;
                height: 9.2rem;
                margin: 0 auto 0.8rem;
            }

            span {
                width: 80%;
                margin: 0 auto;
                font-size: 1.2rem;
            }

            &:nth-of-type(1) .img img {
                left: 50%;
                width: 8rem;
            }

            &:nth-of-type(2) .img img {
                width: 7.3rem;
            }

            &:nth-of-type(3) .img img {
                width: 7rem;
            }
        }
    }
    .hashtag {
        margin: 0.4rem 0 1.6rem;
        font-size: 2.65rem;
    }
}
</style>
