<template lang="pug">
    .hand(:class="[activeColor]")
        span.black
        span.blue
        span.gold
        span.pink
        span.vanillia
</template>

<script>
export default {
    name: "hand",
    props: {
        activeColor: {
            required: true,
        },
    }
}
</script>

<style scoped lang="scss">
.hand {
    position: absolute;

    &.black span.black,
    &.blue span.blue,
    &.gold span.gold,
    &.pink span.pink,
    &.vanillia span.vanillia {
        z-index: 1;
        opacity: 1;
        transition: opacity 1s;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        transition: opacity .5s 1s;
        opacity: 0;
    }

    span {
        background: no-repeat left top / contain;

        &.black {
            background-image: url(../../../assets/img/game/hand/black.png);
        }

        &.blue {
            background-image: url(../../../assets/img/game/hand/blue.png);
        }

        &.pink {
            background-image: url(../../../assets/img/game/hand/pink.png);
        }

        &.vanillia {
            background-image: url(../../../assets/img/game/hand/vanillia.png);
        }

        &.gold {
            background-image: url(../../../assets/img/game/hand/gold.png);
        }
    }
}
</style>
