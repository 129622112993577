<template lang="pug">
    .app-checkbox(:class="classes")
        .app-checkbox__toggle(@click="toggle")
        .app-checkbox__label(@click="toggle")
            slot
</template>

<script>
export default {
    name: "app-checkbox",

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        label: {
            type: [Number, String],
            default: '',
        },
        error:{
            default: false
        }
    },

    computed: {
        classes() {
            return {
                'app-checkbox--active': this.value,
                'app-checkbox--invalid': this.error,
            }
        }
    },

    methods: {
        toggle() {
            this.$emit('input', !this.value);
        }
    }
}

</script>

<style scoped lang="scss">
.app-checkbox {
    display: flex;
    align-items: center;

    &__toggle {
        position: relative;

        width: rem(24px);
        height: rem(24px);

        border: 1px solid #906530;
        background-color: #fff;

        cursor: pointer;
        transition: background-position .2s;
        background-size: 80% auto;
        background-position: center 0;
        background-repeat: no-repeat;
    }

    &__label {
        display: flex;
        align-items: center;
        padding-left: 0.7rem;
        padding-top: 2px;
        font-weight: 300;
        color: #fff;
        cursor: pointer;
    }

    &--active {
        .app-checkbox__toggle {
            background-image: url(../../../assets/img/icons/checked.png);
            background-position: center 50%;
            background-color: #fff;
        }
    }
    &--invalid {
        .app-checkbox__toggle {
            border-width: 2px;
            border-color: $red;
        }
    }
}

@include mobile_or_P {
    .app-checkbox{
        &__toggle{
            width: 1.6rem;
            height: 1.6rem;
        }
        &__label{
            font-size: 1.36rem;
        }
    }
}
</style>
