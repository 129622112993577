<template lang="pug">
    .draw-result(:class="{wrong: result==='wrong'}" ref="draw-result")
        .container(v-min-height)
            .left
                taste-number(:number="taste.number" :activeColor="activeColor")
                template(v-if="result==='correct'")
                    p Это
                        b.gradient-text {{ currentWord[0] }}!
                    p Отлично, <br>продолжайте<br> в том же духе!
                template(v-else)
                    //h5 Не расстраивайтесь!
                    p(v-html="'Не расстраивайтесь, в&nbsp;следующем задании обязательно получится!'")
                app-button(@click.native="$emit('continue')") Дальше
            .right(v-if="result==='correct'")
                p Узнайте еще больше <br>о капсуле <span>{{ taste.name2 }}</span>
                .name
                    span Кофе
                    | &nbsp;{{ taste.name }}
                    b &nbsp;{{ taste.name2 }}
                hr
                .description(v-html="taste.description")
                app-button.forMobile_or_p(@click.native="$emit('continue')") Дальше
            white-shadow(data-parallax="-5:-5:0")
            white-circle
            img.glass(v-if="result==='wrong'" src="@/assets/img/game/glass.png")
            cup.cup1(v-if="result==='correct'" :activeColor="activeColor" data-parallax="-4:-3:3")
            cup.cup2(v-if="result==='correct'" :activeColor="activeColor" data-parallax="-2:-2:2")
            cup.cup3(v-if="result==='wrong'" :activeColor="activeColor" :couple="true" data-parallax="-2:-2:-5")
            pack(v-if="result==='correct'" :activeColor="activeColor" data-parallax="-2:-2:-2")

</template>

<script>
import TasteNumber from "@/components/game/other/tasteNumber";
import AppButton from "@/components/app/app-button/index";
import WhiteShadow from "@/components/game/decor/whiteShadow";
import WhiteCircle from "@/components/game/decor/whiteCircle";
import Cup from "@/components/game/decor/cup";
import Pack from "@/components/game/decor/pack";
import parallax from "@/mixins/parallax";

export default {
    name: "drawResult",
    components: {Pack, Cup, WhiteCircle, WhiteShadow, AppButton, TasteNumber},
    props: {
        activeColor: {
            required: true,
        },
        currentWord: {
            default: ()=>({}),
            required: true
        },
        taste: {
            required: true
        },
        result: {
            required: true, // correct, wrong
        }
    },

    mixins: [parallax],

    mounted() {
        if (!window.isMobileOrTablet) {
            this.initParallax(this.$refs['draw-result']);
        }
    },

}
</script>

<style scoped lang="scss">
.container{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 23.6vh;
    padding-bottom: 15vh;
}
.left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 27rem;
    @media screen and (min-height: 800px) and (width: 1280px) {
        max-width: 25rem;
    }
    h5{
        margin-bottom: -2.5vh;
        font-size: rem(50px);
        font-weight: 100;
    }
    p{
        font-size: rem(30px);
        line-height: 1.2;
        font-weight: 100;
        &:nth-of-type(1){
            margin-bottom: -4vh;
        }
        &:nth-of-type(2){
            margin-bottom: 5vh;
        }
    }
    b{
        display: block;
        margin-top: 0.5rem;
        font-size: rem(50px);
        line-height: 1.2;
        font-weight: 300;
        text-transform: uppercase;
    }
}
.right{
    max-width: 20.5rem;
    padding-top: 0.2rem;
    p{
        margin-bottom: 8.8vh;
        font-size: 1.5rem;
        line-height: 1.25;
        letter-spacing: -0.01rem;
        span {
            text-transform: uppercase;
        }
    }
    .name{
        font-size: rem(30px);
        letter-spacing: -0.02rem;
        b{
            display: block;
            font-size: rem(50px);
            line-height: 1.6;
            letter-spacing: 0.3rem;
            text-transform: uppercase;
        }
    }
    hr{
        margin: 1rem 0 2.4rem;
    }
    .description{
        font-size: 1.25rem;
        line-height: 1.23;
        font-weight: 300;
        letter-spacing: -0.03rem;
    }
}
.whiteShadow{
    top: calc(49% - 70vh / 2);
    left: calc(52.6% - 70vh / 2);
    width: 70vh;
    height: 70vh;
}
.whiteCircle{
    top: calc(52% - 43vh / 2);
    left: calc(49.6% - 43vh / 2);
    width: 43vh;
    height: 43vh;
    @include razr1024 {
        top: calc(52% - 25rem / 2);
        left: calc(49.6% - 25rem / 2);
        width: 25rem;
        height: 25rem;
    }
}
.cup1{
    top: 26%;
    left: 35%;
    width: 14vh;
    height: 14vh;
    &::v-deep span{
        transform: rotate(2deg);
    }
}
.cup2{
    top: 63.5%;
    left: 57.5%;
    width: 13vh;
    height: 12vh;
    &::v-deep span{
        transform: rotate(138deg);
    }
}
.cup3{
    top: calc(50% + 8.5vh);
    left: calc(63% + 20vh);
    width: 20.4vh;
    height: 16vh;
}
.glass{
    position: absolute;
    top: 53.8%;
    left: 62.8%;
    transform: translate(-50%, -50%);
    width: 23.8vh;
}
.pack{
    top: calc(72% - 70vh / 2);
    left: calc(37.6% - 33vh / 2);
    width: 70vh;
    height: 33vh;
}
.draw-result.wrong{
    .whiteCircle{
        top: calc(50% - 43vh / 2);
        left: calc(63% - 43vh / 2);
    }
    .whiteShadow{
        top: calc(48% - 70vh / 2);
        left: calc(66.6% - 70vh / 2);
    }
}
@include razr1366 {
    .pack {
        left: calc(40.6% - 35vh / 2);
    }
}
@media screen and (min-height: 800px) and (width: 1280px) {
    .pack {
        top: calc(74% - 72vh / 2);
        left: calc(40.6% - 35vh / 2);
        width: 57vh;
    }
}
@include razr1024 {
    .pack {
        top: calc(52% - 16rem / 2);
        left: calc(49.6% - 41rem / 2);
        width: 41rem;
        height: 18rem;
    }
}

@include mobile_or_P{
    .container{
        flex-direction: column;
        padding-top: 9.5rem;
        padding-bottom: 3.6rem;
    }
    .left{
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        margin-bottom: 19rem;
        .taste-number{
            margin-bottom: 2.25rem;
        }
        p{
            width: 100%;
            font-size: 1.9rem;
            text-align: center;
            br{
                display: none;
            }
            &:nth-of-type(1){
                margin-bottom: 0.9rem;
                text-transform: lowercase;
            }
        }
        b{
            margin-top: 0;
            font-size: 3.45rem;
            line-height: 1.1;
            letter-spacing: 0.35rem;
        }
    }
    .right{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        p{
            display: none;
        }
        .name{
            width: 100%;
            font-size: 2.7rem;
            letter-spacing: 0.15rem;
            text-align: center;
            span{
                display: none;
            }
            b{
                display: inline;
                font-size: 2.7rem;
                letter-spacing: inherit;
            }
        }
        hr{
            margin: 0.4rem 0 1.1rem;
        }
        .description{
            margin-bottom: 3.5rem;
            font-size: 1.67rem;
            text-align: center;
        }
    }
    .whiteCircle{
        top: calc(50.3% - 17.5rem / 2);
        left: calc(50% - 17.5rem / 2);
        width: 17.5rem;
        height: 17.5rem;
    }
    .whiteShadow{
        top: calc(49% - 27rem / 2);
        left: calc(51% - 25rem / 2);
        width: 27rem;
        height: 27rem;
    }
    .pack{
        top: calc(52.6% - 17rem / 2);
        left: calc(48.6% - 28rem / 2);
        width: 29rem;
        height: 17rem;
    }
    .cup1{
        top: calc(50% - 10rem);
        left: calc(50% - 11.5rem);
        width: 7.5rem;
        height: 7.5rem;
    }
    .cup2{
        top: calc(50% + 0.5rem);
        left: calc(50% + 5.8rem);
        width: 5.5rem;
        height: 5.5rem;
        &::v-deep span{
            transform: rotate(124deg);
        }
    }
    @include landscape{
        .whiteCircle{
            top: calc(53.3% - 17.5rem / 2);
        }
        .whiteShadow{
            top: calc(52% - 27rem / 2);
        }
        .pack{
            top: calc(55.6% - 17rem / 2);
        }
        .cup1{
            top: calc(53% - 10rem);
        }
        .cup2{
            top: calc(53% + 0.5rem);
        }
    }
    .draw-result:not(.wrong){
        .left .button{
            display: none;
        }
    }
    .draw-result.wrong{
        .container{
            padding-top: 0;
            padding-bottom: 0;
        }
        .left{
            justify-content: space-between;
            min-height: inherit;
            margin-bottom: 0;
            padding-top: 9.5rem;
            padding-bottom: 3.6rem;
            .taste-number {
                margin-bottom: 23rem;
            }
            h5{
                margin-bottom: 22rem;
            }
            p{
                &:nth-of-type(1){
                    margin-bottom: 5rem;
                    text-transform: initial;
                }
                br{
                    display: initial;
                }
            }
        }
        .whiteCircle{
            top: calc(40% - 17.5rem / 2);
            left: calc(50% - 17.5rem / 2);
        }
        .whiteShadow{
            top: calc(39% - 27rem / 2);
            left: calc(51% - 25rem / 2);
        }
    }
    .glass{
        top: 42.5%;
        left: 49.8%;
        width: 9.9rem;
    }
    .cup3{
        top: calc(40% + 3rem);
        left: calc(50% + 7rem);
        width: 8.4rem;
        height: 7rem;
    }
}
</style>
