<template lang="pug">
    .luminescence(:class="[activeColor]")
        span.black
        span.blue
        span.gold
        span.pink
        span.vanillia
</template>

<script>
export default {
    name: "luminescence",
    props: {
        activeColor: {
            required: true,
        },
    }
}
</script>

<style scoped lang="scss">
.luminescence{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.black span.black,
    &.blue span.blue,
    &.gold span.gold,
    &.pink span.pink,
    &.vanillia span.vanillia{
        opacity: 1;
    }
    span{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        background: no-repeat center center / cover;
        transition: opacity 2s;
        opacity: 0;
    }
    span{
        &.black{
            background-image: url(../../../assets/img/game/luminescence/black.png);
        }
        &.blue{
            background-image: url(../../../assets/img/game/luminescence/blue.png);
        }
        &.gold{
            background-image: url(../../../assets/img/game/luminescence/gold.png);
        }
        &.pink{
            background-image: url(../../../assets/img/game/luminescence/pink.png);
        }
        &.vanillia{
            background-image: url(../../../assets/img/game/luminescence/vanillia.png);
        }
    }
}
</style>
