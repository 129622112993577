<template lang="pug">
    .corn-answer(:class="activeColor")
        span(v-html="versionText")
        b {{ version }}
</template>

<script>
export default {
    name: "cornAnswer",
    props:{
        activeColor: {
            required: true,
        },
        versionText: {
            required: true,
        },
        version:{
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
.corn-answer {
    position: relative;
    display: inline-block;
    padding: 1.55rem 2.1rem 1.55rem 1.8rem;
    border-radius: 0.5rem;
    font-size: 2.5rem;
    font-weight: 100;
    letter-spacing: 0.025rem;
    white-space: nowrap;
    @include mobile_or_P {
        width: max-content;
        white-space: initial;
    }
    &:before{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        border: 0.5rem solid $black;
        border-right-color: transparent;
        border-top-color: transparent;
        border-bottom-color: transparent;
        transform: translateY(50%);
    }
    &.black{
        background-color: $black;
        &:before{
            border-left-color: $black;
        }
    }
    &.blue{
        background-color: $blue;
        &:before{
            border-left-color: $blue;
        }
    }
    &.gold{
        background-color: $gold;
        &:before{
            border-left-color: $gold;
        }
    }
    &.pink{
        background-color: $pink;
        &:before{
            border-left-color: $pink;
        }
    }
    &.vanillia{
        background-color: $vanillia;
        &:before{
            border-left-color: $vanillia;
        }
    }
    b {
        font-weight: bold;
    }
}
</style>
