<template lang="pug">
    .miniCups
        .cup.black(:class="{current:activeTaste===0, filled: drawnTastes>0}")
        .cup.blue(:class="{current:activeTaste===1, filled: drawnTastes>1}")
        .cup.pink(:class="{current:activeTaste===2, filled: drawnTastes>2}")
        .cup.vanillia(:class="{current:activeTaste===3, filled: drawnTastes>3}")
        .cup.gold(:class="{current:activeTaste===4, filled: drawnTastes>4}")
</template>

<script>
export default {
    name: "miniCups",
    props: {
        activeTaste: {
            required: true,
        },
        drawnTastes: {
            required: true,
        },
    }
}
</script>

<style scoped lang="scss">
.miniCups{
    z-index: 1;
    left: 6.5rem;
    top: 5vh;
    position: absolute;
    display: flex;
}
.cup {
    width: 4rem;
    height: 4rem;
    margin-right: rem(34px);
    border-radius: 50%;
    border: 1px solid;
    background: no-repeat center center / 70% auto;
    transition: .3s;
    opacity: 0.4;
    &.black {
        border-color: #fff;
        background-image: url(../../../assets/img/game/cup1/black.png);
        background-color: #000;
    }

    &.blue {
        border-color: $blue;
        background-image: url(../../../assets/img/game/cup1/blue.png);
        background-color: $blue;
    }

    &.gold {
        border-color: $gold;
        background-image: url(../../../assets/img/game/cup1/gold.png);
        background-color: $gold;
    }

    &.pink {
        border-color: $pink;
        background-image: url(../../../assets/img/game/cup1/pink.png);
        background-color: $pink;
    }

    &.vanillia {
        border-color: $vanillia;
        background-image: url(../../../assets/img/game/cup1/vanillia.png);
        background-color: $vanillia;
    }
    &:not(.filled){
        background-color: transparent;
    }
    &.filled{
        opacity: 1;
        box-shadow: 0 0 1.4rem 0.1rem $gold;
        &.black{
            border-color: #000;
        }
    }
    &.current{
        opacity: 1;
    }
}
@include mobile_or_P{
    .miniCups{
        top: 2.25rem;
        left: 50%;
        transform: translateX(-50%);
    }
    .cup{
        width: 3.6rem;
        height: 3.6rem;
        margin: 0 1rem;
    }
}
</style>
