<template lang="pug">
    .intro(:class="[activeColor]" ref="intro")
        .container(v-min-height)
            .title(v-html="'Преобразуйте реальность <br> вместе с капсулами кофе&nbsp;Jardin!'")
            .bottom
                app-button(@click.native="startGameHandler") Начать игру
                .rules
                    .rules__item.mouse
                        .rules__img
                            img(src="@/assets/img/icons/rules_mouse.png")
                        | Рисуйте мышкой
                    .rules__item.finger
                        .rules__img
                            img(src="@/assets/img/icons/rules_finger.png")
                        | Рисуйте пальцем
                    .rules__item
                        .rules__img
                            img(src="@/assets/img/icons/rules_time.png")
                        | 20 секунд на выполнение
                    .rules__item
                        .rules__img
                            img(src="@/assets/img/icons/rules_star.png")
                        | 5 уровней
        luminescence(:activeColor="activeColor")
        white-shadow.decor--shadow(data-parallax="-7:-5:0")
        white-circle.decor--circle
        hand.decor--hand(:activeColor="activeColor" data-parallax="3:4:6")
        cup.decor--cup1(:activeColor="activeColor" data-parallax="7:1:3")
        cup.decor--cup2(:activeColor="activeColor" :alternate="true" data-parallax="3:-2:2")
</template>

<script>
import AppButton from "@/components/app/app-button/index";
import Cup from "@/components/game/decor/cup";
import Hand from "@/components/game/decor/hand";
import Luminescence from "@/components/game/decor/luminescence";
import whiteCircle from "@/components/game/decor/whiteCircle";
import whiteShadow from "@/components/game/decor/whiteShadow";
import parallax from "@/mixins/parallax";

export default {
    name: "intro",
    components: {
        whiteShadow,
        whiteCircle,
        Luminescence,
        Hand,
        Cup,
        AppButton
    },

    data: () => ({
        activeI: 0,
    }),

    mixins: [parallax],

    computed: {
        activeColor() {
            return this.activeI === 0 ? 'black' :
                this.activeI === 1 ? 'blue' :
                    this.activeI === 2 ? 'pink' :
                        this.activeI === 3 ? 'vanillia' :
                            this.activeI === 4 ? 'gold' : 'black';
        },
    },
    methods: {
        startGameHandler() {
            this.$emit('start');
            if (window.location.hostname !== 'localhost') {
                ga('send', 'event', 'click', 'game', 'start');
                console.log("ga('send', 'event','click', 'game', 'start')");
            }
        }
    },
    mounted() {
        if (!window.isMobileOrTablet) {
            this.initParallax(this.$refs.intro);
        }
    },
}
</script>

<style scoped lang="scss">
.intro {
    position: relative;
    overflow-x: hidden;
    background: url(../../assets/img/game/cup.png) no-repeat 16% -7vh/auto 92% #000;
}

.container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 9.2vh;
    padding-bottom: 15vh;
}

.title {
    font-size: rem(30px);
    line-height: 1.7;
    letter-spacing: 0.16rem;
    text-transform: uppercase;
    text-align: center;
}

.bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.rules {
    padding: 0 3.4rem 0.45rem 0;

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 0.65rem;
        font-size: 1.5rem;

        &.finger {
            display: none;
        }
    }

    &__img {
        display: flex;
        justify-content: flex-end;
        width: rem(60px);
        margin-right: rem(28px);

        img {
            height: rem(50px);
        }
    }
}

.decor {
    &--shadow {
        top: calc(50% - 76vh / 2);
        left: calc(50% - 76vh / 2);
        transition: .1s;
    }

    &--circle {
        top: 49.2%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--hand {
        top: calc(48.5% - 53.3vh / 2);
        left: calc(58.4% - 86.4vh / 2);
        width: 86.4vh;
        height: 53.3vh;
        transition: .1s;
    }

    &--cup1 {
        top: 53%;
        left: 26%;
        width: 14vh;
        height: 14vh;
        @media screen and (width: 1280px) and (min-height: 750px) {
            left: 21%;
        }

        &::v-deep span {
            transform: rotate(-47deg);
        }

        transition: .1s;
    }

    &--cup2 {
        top: 40%;
        left: 18.2%;
        width: 9vh;
        height: 9.4vh;
        transition: .1s;
        @media screen and (width: 1280px) and (min-height: 750px) {
            left: 13%;
        }
    }
}

.isMobileOrTablet {
    .rules {
        &__item {
            &.finger {
                display: flex;
            }

            &.mouse {
                display: none;
            }
        }
    }
}

@include mobile_or_P {
    .intro {
        background-position: 17% -6.3rem;
        background-size: 92.5vmin auto;
        @media screen and (min-width: 768px) {
            background-position: center bottom 2rem;
            background-size: auto 100%;
        }
    }
    .container {
        padding-top: 2.7rem;
        padding-bottom: 3.6rem;
    }
    .title {
        line-height: 1.35;
    }
    .bottom {
        flex-direction: column-reverse;
        align-items: center;
    }
    .rules {
        padding: 0 1rem 4.9rem 0;
        @media screen and (max-height: 480px) {
            padding-bottom: 2.5rem;
        }

        &__item {
            margin-bottom: 0.7rem;
            font-size: 1.88rem;
        }

        &__img {
            width: 3.75rem;
            margin-right: 2.1rem;

            img {
                height: 4.25rem;
            }
        }
    }
    .decor {
        &--circle {
            top: 36%;
        }

        &--shadow {
            top: calc(36% - 36rem / 2);
            left: calc(50% - 36rem / 2);
        }

        &--hand {
            top: calc(37% - 24rem / 2);
            left: calc(65.4% - 38rem / 2);
            width: 38rem;
            height: 24rem;
        }

        &--cup1 {
            top: 42%;
            left: calc(50% + 6.4rem);
            width: 6.5rem;
            height: 6.5rem;

            &::v-deep span {
                transform: initial;
            }
        }

        &--cup2 {
            top: 21%;
            left: calc(50% - 15rem);
            width: 4.5rem;
            height: 4.5rem;
        }
    }
    @include landscape {
        .title {
            margin-bottom: 26rem;
        }
        .rules {
            padding-bottom: 2rem;
        }
    }
}
</style>
