<template lang="pug">
.whiteShadow
</template>

<script>
export default {
    name: "whiteShadow"
}
</script>

<style scoped lang="scss">
.whiteShadow{
    position: absolute;
    height: 76vh;
    width: 76vh;
    background: url(../../../assets/img/game/shadow.png) no-repeat center center / 100% 100%;
    @include razr1024 {
        width: 48rem;
        height: 48rem;
    }
    @include mobile_or_P{
        height: 36rem;
        width: 36rem;
    }
}
</style>
