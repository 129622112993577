<template lang="pug">
    #app
        router-view
        orientation-zaglushka
        i-e-zaglushka
</template>

<script>
import detectMobileBrowser from "@/utils/detectmobilebrowser";
import OrientationZaglushka from "@/components/other/OrientationZaglushka";
import IEZaglushka from "@/components/other/IEZaglushka";

export default {
    components: {IEZaglushka, OrientationZaglushka},
    beforeMount() {
        window.isMobileOrTablet = detectMobileBrowser();
        if (window.isMobileOrTablet){
            document.body.classList.add('isMobileOrTablet');
        }
    }
}
</script>

<style lang="scss">

</style>
