<template lang="pug">
    .orientationZaglushka Поверните устройство в вертикальное положение
</template>

<script>
export default {
    name: "orientationZaglushka"
}
</script>

<style scoped lang="scss">
.orientationZaglushka {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
    background: #000;
    font-size: 3rem;
    text-align: center;
}

body.isMobileOrTablet {
    @media screen and (orientation: landscape) and (min-width: 470px) and (max-width: 1023px) {
        .orientationZaglushka {
            display: flex;
        }
    }
}
</style>
