<template lang="pug">
    .draw-place
        canvas#canvas
        .clear(ref="clear")
        .lastik(:class="activeColor")
</template>

<script>
export default {
    name: "drawPlace",
    props: {
        activeColor: {
            required: true,
        },
        roundId: {
            required: true,
        },
    },
    data: () => ({
        checkTimeout: 1500,
        canCheck: true,
        needCheck: false,
    }),
    computed: {
        color() {
            return this.activeColor === 'black' ? '#000' :
                this.activeColor === 'blue' ? '#5a85b5' :
                    this.activeColor === 'pink' ? '#ac5c8b' :
                        this.activeColor === 'vanillia' ? '#906530' :
                            this.activeColor === 'gold' ? '#FFC658' : '#000';
        }
    },
    mounted() {
        var path, ink;
        var timer = 0, lastTimestamp = 0, lastTimestamp_check = 0;

        paper.install(window);

        initInk();
        paper.setup('canvas');

        var tool = new Tool();


        let checkDrawing = async () => {
            if (!ink[0].length && !ink[1].length && !ink[2].length) {
                this.canCheck = true;
                this.needCheck = false;
                return;
            }
            const c_dims = getCanvasDimensions();
            let width = c_dims.width;
            let height = c_dims.height;
            try {
                const response = await this.axios.post('/game/round/play', {
                    round: this.roundId,
                    data: JSON.stringify(ink),
                    width,
                    height
                });
                if (response.data.move) {
                    this.$emit('checkDrawingResult', response.data)
                    window.checkDrawingTimerId = setTimeout(() => {
                        if (this.needCheck) {
                            this.needCheck = false;
                            checkDrawing()
                        } else {
                            this.canCheck = true;
                        }
                    }, this.checkTimeout);
                } else {
                    this.needCheck = false;
                    this.canCheck = true;
                }


            } catch (e) {
                console.log(e)
            }
        };

        tool.onMouseDown = event => {
            path = new Path();
            path.strokeColor = this.color;
            path.strokeWidth = 7;

            var thisTimestamp = event.event.timeStamp;
            if (timer === 0) {
                timer = 1;
                var time = 0;
            } else {
                var timeDelta = thisTimestamp - lastTimestamp;
                var time = ink[2][ink[2].length - 1] + timeDelta;
            }

            updateInk(event.point, time);
            path.add(event.point);

            lastTimestamp = thisTimestamp;
        }

        tool.onMouseDrag = event => {
            var thisTimestamp = event.event.timeStamp;
            var timeDelta = thisTimestamp - lastTimestamp;
            var time = ink[2][ink[2].length - 1] + timeDelta;

            updateInk(event.point, time);
            path?.add(event.point);

            lastTimestamp = thisTimestamp;
            /*if (thisTimestamp - lastTimestamp_check > 250) {

                if (this.canCheck) {
                    this.canCheck = false;
                    checkDrawing()
                } else {
                    this.needCheck = true;
                }

                lastTimestamp_check = thisTimestamp;
            }*/
            if (this.canCheck) {
                this.canCheck = false;
                checkDrawing()
            } else {
                this.needCheck = true;
            }
        };

        function initInk() {
            ink = [[], [], []];
        }

        function clearDrawing() {
            paper.project.activeLayer.removeChildren();
            paper.view.draw();

            initInk();

            timer = 0;
        }

        this.$refs.clear.addEventListener('click', clearDrawing);

        function updateInk(point, time) {
            ink[0].push(point.x);
            ink[1].push(point.y);
            ink[2].push(time);
        }

        function getCanvasDimensions() {
            var w = document.getElementById('canvas')?.offsetWidth;
            var h = document.getElementById('canvas')?.offsetHeight;
            return {height: h, width: w};
        }
    },

    beforeDestroy() {
        paper.remove();
        clearTimeout(window.checkDrawingTimerId);
    }
}
</script>

<style scoped lang="scss">
.draw-place {
    position: relative;
    width: 86vh;
    height: 76vh;
    @media screen and (min-height: 950px) {
        width: 73vh;
        height: 64.6vh;
    }
    @include razr1024 {
        width: 48rem;
        height: 43rem;
    }

    canvas {
        position: absolute;
        width: 100%;
        height: 100%;
        clip-path: polygon(8% 0.5%, 100% 9%, 91% 100%, 1% 93.3%);
    }

    .clear {
        position: absolute;
        bottom: 91%;
        right: 3.8%;
        width: 12.5%;
        height: 11.8%;
        cursor: pointer;
        clip-path: polygon(8% 0.5%, 98% 9%, 92% 97%, 1% 88%);

        &:hover + .lastik:after {
            transform: rotate(-5.5deg) scale(1.1);
        }
    }

    .lastik {
        position: absolute;
        z-index: -1;
        bottom: 91.3%;
        right: 4.4%;
        width: 11.5%;
        height: 11%;
        border-radius: 0.7rem 0.7rem 0 0;
        transform: rotate(5.5deg);

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url(../../../assets/img/game/lastik.png) no-repeat 54% 75% / 69% auto;
            transform: rotate(-5.5deg);
            transition: transform .3s;
        }

        &.black {
            background-color: $black;
        }

        &.blue {
            background-color: $blue;
        }

        &.pink {
            background-color: $pink;
        }

        &.vanillia {
            background-color: $vanillia;
        }

        &.gold {
            background-color: $gold;
        }
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -17.9%;
        left: -34.6%;
        width: 191%;
        height: 130%;
        background: url(../../../assets/img/game/paper.png) no-repeat left top / contain;
    }

    @media screen and (min-height: 800px) and (width: 1280px) {
        width: 69.6vh;
        height: 61.5vh;
    }
    @include mobile_or_P {
        width: 39rem;
        height: 48.5rem;
        .clear {
            bottom: 100.3%;
            right: 13%;
            width: 13%;
            height: 11%;
        }
        .lastik {
            bottom: 100.3%;
            right: 13%;
            width: 13%;
            height: 11%;
            transform: initial;

            &:after {
                background-position-y: 40%;
                background-size: 57% auto;
                transform: initial;
            }
        }
        &:before {
            top: -2.9%;
            left: -7.6%;
            width: 108%;
            height: 110%;
            background: url(../../../assets/img/game/paper-mobile.png) no-repeat left top / contain;
        }
        canvas {
            clip-path: none;
        }
    }
}
</style>
