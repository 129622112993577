<template lang="pug">
    .cup(:class="cupClasses")
        span.black
        span.blue
        span.gold
        span.pink
        span.vanillia
</template>

<script>
export default {
    name: "cup",
    props: {
        activeColor:{
            required: true,
        },
        alternate:{
            default: false
        },
        couple: {
            default: false
        }
    },
    computed:{
        cupClasses(){
            return [
                this.activeColor,
                this.alternate ? 'alternate':'',
                this.couple ? 'couple':''
            ];
        }
    }
}
</script>

<style scoped lang="scss">
.cup{
    position: absolute;
    &.black span.black,
    &.blue span.blue,
    &.gold span.gold,
    &.pink span.pink,
    &.vanillia span.vanillia{
        z-index: 1;
        opacity: 1;
        transition: opacity 1s;
    }
    span{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        transition: opacity .5s 1s;
        opacity: 0;
    }
    span{
        background: no-repeat left top / contain;
        &.black{
            background-image: url(../../../assets/img/game/cup1/black.png);
        }
        &.blue{
            background-image: url(../../../assets/img/game/cup1/blue.png);
        }
        &.gold{
            background-image: url(../../../assets/img/game/cup1/gold.png);
        }
        &.pink{
            background-image: url(../../../assets/img/game/cup1/pink.png);
        }
        &.vanillia{
            background-image: url(../../../assets/img/game/cup1/vanillia.png);
        }
    }
    &.alternate{
        span{
            &.black{
                background-image: url(../../../assets/img/game/cup2/black.png);
            }
            &.blue{
                background-image: url(../../../assets/img/game/cup2/blue.png);
            }
            &.gold{
                background-image: url(../../../assets/img/game/cup2/gold.png);
            }
            &.pink{
                background-image: url(../../../assets/img/game/cup2/pink.png);
            }
            &.vanillia{
                background-image: url(../../../assets/img/game/cup2/vanillia.png);
            }
        }
    }
    &.couple{
        span{
            &.black{
                background-image: url(../../../assets/img/game/cup_couple/black.png);
            }
            &.blue{
                background-image: url(../../../assets/img/game/cup_couple/blue.png);
            }
            &.gold{
                background-image: url(../../../assets/img/game/cup_couple/gold.png);
            }
            &.pink{
                background-image: url(../../../assets/img/game/cup_couple/pink.png);
            }
            &.vanillia{
                background-image: url(../../../assets/img/game/cup_couple/vanillia.png);
            }
        }
    }
}
</style>
